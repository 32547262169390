import React, { Component } from 'react';
import firebase from '../../../config/fbConfig';
import FileUploader from 'react-firebase-file-uploader';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { uploadSucces, uploadError } from '../../../store/actions/fileActions';

class Uploader extends Component {

  state = {
        avatar: '',
        isUploading: false,
        progress: 0,
  };


  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleSuccess = filename => {
    const { type, officeId } = this.props;
    this.setState({isUploading: false, progress: 100});
    //this.props.uploadSucces(identifier, filename, beschrijving, officeId)
    this.props.uploadSucces(type, filename, filename, officeId)

  }

  render() {
    const { type, name, show, officeId } = this.props;
    return (
      <label style={{backgroundColor: '#ED7307', color: 'white',  paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 4, cursor: 'pointer'}}>
        <p>{show}</p>
        <FileUploader
          hidden
          accept="image/*, application/pdf"
          filename={name}
          storageRef={firebase.app().storage("gs://documentenoffices").ref(`${officeId}/${type}`)}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.props.uploadError}
          onUploadSuccess={this.handleSuccess}
          onProgress={this.handleProgress}
          /> 
      </label>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadSucces: (identifier, filename, beschrijving, officeId) => dispatch(uploadSucces(identifier, filename, beschrijving, officeId)),
        uploadError: () => dispatch(uploadError())
    }
}

export default compose(connect(null, mapDispatchToProps))(Uploader)