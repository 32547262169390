import React from 'react';
import { connect } from 'react-redux'
import { newKlantAction } from '../../../store/actions/authActions'
import 'react-datetime/css/react-datetime.css';
import { compose } from 'redux'
import {
    CardFooter,
    Row,
    Col,
    CardBody,
} from 'reactstrap';
import { FormItem } from '../../DMComponents/FormComponents';




class NewKlant extends React.Component {
    
  state = {
    formReadOnly: false,
    office: {
      bezoekAdres: '',
      bezoekPc: '',
      bezoekPlaats: '',
      postAdres: '',
      postPc: '',
      postPlaats: '',
      officeName: '',

    },
    company: {
      companyName: '',
      kvk: '',
      btw: ''
    },
    newUser: {
      admin: '',
      status: 'pending',
      doorkiesnr: '',
      email: '',
      firstName: '',
      lastName: '',
      midName: '',
      gsmnummer: '',
    }
  }


  validateOnChange = (event, category) => {
    console.log(this.state)
    const input = event.target;
    //const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      [category]: {
        ...this.state[category],
        [input.name]: value,
      }
    })
  }
  

  onSubmit = e => {
    e.preventDefault()
    if (!this.state.formReadOnly) {
      this.props.newKlantAction(this.state)
      this.setState({
        ...this.state,
        formReadOnly: true
      })
    }
  }

  render() {
  // Usse t function instead of Trans component
    return (
      <form onSubmit={this.onSubmit} action="" name="gegevens">
        <div className="col-md-12">
            <CardBody>
                <legend className="mb-4">Bedrijfsgegevens</legend>
                <fieldset>
                    <FormItem
                        name= "companyName"
                        nameShow= "Bedrijfsnaam" 
                        onChange={event => this.validateOnChange(event, 'company')}
                        value={this.state['company']['companyName']}
                        readOnly={this.state.formReadOnly}  
                    /> 
                    <FormItem
                        name= "btw"
                        nameShow= "BTW nummer"
                        onChange={event => this.validateOnChange(event, 'company')}
                        value={this.state['company']['btw']}  
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "kvk"
                        nameShow= "KVK nummer"
                        onChange={event => this.validateOnChange(event, 'company')}
                        value={this.state['company']['kvk']}
                        readOnly={this.state.formReadOnly}  
                    />  
                </fieldset>
                <legend className="mb-4">Vestigingsgegevens</legend>
                <fieldset>
                    <FormItem
                        name= "officeName"
                        nameShow= "Vestigingsnaam"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['officeName']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "bezoekAdres"
                        nameShow= "Bezoek adres"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['bezoekAdres']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "bezoekPc"
                        nameShow= "Bezoek postcode"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['bezoekPc']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "bezoekPlaats"
                        nameShow= "Bezoek plaats"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['bezoekPlaats']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "postAdres"
                        nameShow= "Post Adres"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['postAdres']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "postPc"
                        nameShow= "Post postcode"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['postPc']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "postPlaats"
                        nameShow= "Post plaats"
                        onChange={event => this.validateOnChange(event, 'office')}
                        value={this.state['office']['postPlaats']}
                        readOnly={this.state.formReadOnly}  
                    />
                </fieldset>
                <legend className="mb-4">Persoonsgegevens admin/owner</legend>
                <fieldset>
                    <FormItem
                        name= "email"
                        nameShow= "E-mailadres"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['email']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "firstName"
                        nameShow= "Initialen"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['firstName']}
                        readOnly={this.state.formReadOnly}  
                    /> 
                    <FormItem
                        name= "firstName"
                        nameShow= "Voornaam"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['firstName']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "midName"
                        nameShow= "Tussenvoegsel"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['midName']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "lastName"
                        nameShow= "Achternaam"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['lastName']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "gsmnummer"
                        nameShow= "Mobiele nummer"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['gsmnummer']}
                        readOnly={this.state.formReadOnly}  
                    />
                    <FormItem
                        name= "doorkiesnr"
                        nameShow= "Doorkies nummer"
                        onChange={event => this.validateOnChange(event, 'newUser')}
                        value={this.state['newUser']['doorkiesnr']}
                        readOnly={this.state.formReadOnly}  
                    />  
                </fieldset>
                
            </CardBody>
        </div>
            <CardFooter>
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                    {!this.state.formReadOnly &&
                        <div className="text-center">
                            <button type="submit" className="btn btn-info">Voeg toe</button>
                        </div>
                    }
                    </Col>
                    <Col lg={3}>
                    </Col>
                </Row>
            </CardFooter>
      </form>
      )
    }    
  }

const mapDispatchToProps = (dispatch) => {
    return {
        newKlantAction: (creds) => dispatch(newKlantAction(creds)),
    }
}

export default compose(
    connect(null, mapDispatchToProps)
)(NewKlant)
