import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardHeader, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../Tables/Datatable';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { EditButton } from '../../DMComponents/FormComponents';


class Subscriptions extends Component {

    state = {
        dtOptions2: {
            'paging': true, // Table pagination
            'ordering': true, // Column ordering
            'info': true, // Bottom left status text
            responsive: true,
            // Text translation options
            // Note the required keywords between underscores (e.g _MENU_)
            oLanguage: {
                sSearch: '<em class="fa fa-search"></em>',
                sLengthMenu: '_MENU_ records per page',
                info: 'Showing page _PAGE_ of _PAGES_',
                zeroRecords: 'Nothing found - sorry',
                infoEmpty: 'No records available',
                infoFiltered: '(filtered from _MAX_ total records)',
                oPaginate: {
                    sNext: '<em class="fa fa-caret-right"></em>',
                    sPrevious: '<em class="fa fa-caret-left"></em>'
                }
            },

            // Datatable Buttons setup

        },
        dropdownOpen: false,
    }

    // Access to internal datatable instance for customizations
    dtInstance = dtInstance => {
        const inputSearchClass = 'datatable_input_col_search';
        const columnInputs = $('tfoot .' + inputSearchClass);
        // On input keyup trigger filtering
        columnInputs
            .keyup(function () {
                dtInstance.fnFilter(this.value, columnInputs.index(this));
            });
    }

    render() {

        const { subscriptions } = this.props

        return (
                    <Card>
                        <CardHeader>
                            <Link to={'/subscription/newsubscription'}><button className="btn btn-info">Abonnement artikel toevoegen</button></Link>
                        </CardHeader>
                        <CardBody>
                            {subscriptions ?
                                <Datatable options={this.state.dtOptions2}>
                                    <table className="table table-striped my-4 w-100">
                                        <thead>
                                            <tr>
                                                <th className="sort-alpha">Id Maxxus</th>
                                                <th>Abonnement</th>
                                                <th>Categorie</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subscriptions && Object.keys(subscriptions).map(index => {
                                                return (
                                                    <tr className="grade" key={index}>
                                                        <td>{subscriptions[index].subscriptionIdMaxxus}</td>
                                                        <td>{subscriptions[index].description}</td> 
                                                        <td>{subscriptions[index].category}</td>
                                                        <td><EditButton to={'/subscription/' + index} label={'edit'}/></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Datatable>
                                : <div>Geen abonnementen beschikbaar</div>}
                        </CardBody>
                    </Card>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        offices: state.firestore.data.offices,
        subscriptions: state.firestore.data.subscriptions
    }
}


export default compose(connect(mapStateToProps),
    firestoreConnect([{
        collection: 'subscriptions'
    },
    {
        collection: 'offices'
    }])
)(translate('translations')(Subscriptions))

