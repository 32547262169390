const MenuOwner = [
    {
        heading: 'Maxxus Admin',
    },
    {
      name: 'Logins',
      icon: 'icon-people',
      path: '/logindetails'
    },
    {
      name: 'Logins klanten',
      icon: 'icon-people',
      path: '/all-logins'
    },
    {
      name: 'Abonnementen',
      icon: 'icon-note',
      path: '/subscriptions'
    }
];

export default MenuOwner;
