import React from 'react';
import { connect } from 'react-redux'
import { editUser } from '../../../../store/actions/authActions'
import 'react-datetime/css/react-datetime.css';
import { compose } from 'redux'
import { CardBody, CardFooter, Row, Col, Card } from 'reactstrap';
import ContentWrapper from '../../../Layout/ContentWrapper';
import { FormItem, GenderButton } from '../../../DMComponents/FormComponents';
import { Link } from 'react-router-dom';
import firebase from '../../../../config/fbConfig';

class UserGegevens extends React.Component {

  state = {
    editUser: {
      admin: '',
      status: 'pending',
      doorkiesNr: '',
      email: '',
      firstName: '',
      lastName: '',
      midName: '',
      gsmnummer: '',
      gender: 'NVT'
    },
    rights: {
      owner: false,
      administration: false,
      meldingen: false,
      emailMeldingen: false
    },
    loading: true
  }

  getDataInfo = (id) => {
    const db = firebase.firestore();
    db.collection('users').doc(id).get()
      .then(doc => {
        this.setState({
          editUser: doc.data(),
          loading: false
        })
        db.collection(`offices/${this.props.officeId}/users`).doc(this.props.id).get()
          .then(doc2 => {
            this.setState({
              officeId: doc.data().officeArray[0],
              rights: doc2.data()
            })
          })
          .catch(err => console.log(err))
        this.getOffices(doc.data().officeArray).then(offices => this.setState({ offices }))
      })
      .catch(err => console.log(err))

  }
  componentDidMount() {
    this.getDataInfo(this.props.id)
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
      }
    })
  }


  onSubmit = e => {
    e.preventDefault()
    const form = e.target;

    this.setState({
      [form.name]: {
        ...this.state[form.name],
      }
    });
    this.props.editUser(this.state, this.props.id, this.props.officeId)
  }

  handleGenderSelect = (selectedOption, formName, type) => {
    this.setState({
      [formName]: {
        ...this.state[formName],
        [type]: selectedOption.value,
      },
    })
  }

  checkBoxOnChange = (event, office) => {
    const input = event.target;
    const value = input.checked

    this.setState({
      rights: {
        ...this.state.rights,
        [input.name]: value
      }
    })
  }

  render() {
    const { officeId, signInAsIfToken } = this.props
    const { rights } = this.state
    console.log(signInAsIfToken)
    if (!this.state.loading) {
      return (
        <ContentWrapper>
          <div className="content-heading">
            <Link to={`/klant/${officeId}/contactpersonen`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
          </div>
          <div className="col-md-12">
            <form onSubmit={this.onSubmit} action="" name="editUser">
              <Card className="card-default">
                <CardBody>
                  <legend className="mb-4">Persoonsgegevens admin/owner aanpassen</legend>
                  <fieldset>
                    <FormItem
                      name="email"
                      nameShow="E-mailadres"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser['email']}
                      readOnly={this.state.formReadOnly}
                    />
                    <FormItem
                      name="firstName"
                      nameShow="Voornaam"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser['firstName']}
                      readOnly={this.state.formReadOnly}
                    />
                    <FormItem
                      name="midName"
                      nameShow="Tussenvoegsel"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser['midName']}
                      readOnly={this.state.formReadOnly}
                    />
                    <FormItem
                      name="lastName"
                      nameShow="Achternaam"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser['lastName']}
                      readOnly={this.state.formReadOnly}
                    />
                    <FormItem
                      name="gsmnummer"
                      nameShow="Mobiele nummer"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser['gsmnummer']}
                      readOnly={this.state.formReadOnly}
                    />
                    <FormItem
                      name="doorkiesNr"
                      nameShow="Doorkies nummer"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.editUser.doorkiesNr}
                      readOnly={this.state.formReadOnly}
                    />
                    <GenderButton
                      currentValue={this.state.editUser.gender}
                      onChange={value => this.handleGenderSelect(value, 'editUser', 'gender')}
                    />
                  </fieldset>
                  <fieldset className="last-child">
                    <div className="form-group row align-items-center">
                      <label className="col-md-2 col-form-label">Rechten</label>
                      <Col md={2}>
                        <p>Meldingen</p>
                        <label className="switch">
                          <input name='meldingen' type="checkbox" onChange={event => this.checkBoxOnChange(event, officeId)} checked={officeId !== '' ? rights.meldingen : false} />
                          <span></span>
                        </label>
                      </Col>
                      <Col md={2}>
                        <p>Administratie</p>
                        <label className="switch">
                          <input name='administration' type="checkbox" onChange={event => this.checkBoxOnChange(event, officeId)} checked={officeId !== '' ? rights.administration : false} />
                          <span></span>
                        </label>
                      </Col>
                      <Col md={2}>
                        <p>E-mail meldingen</p>
                        <label className="switch">
                          <input name='emailMeldingen' type="checkbox" onChange={event => this.checkBoxOnChange(event, officeId)} checked={officeId !== '' ? rights.emailMeldingen : false} />
                          <span></span>
                        </label>
                      </Col>
                      <Col md={2}>
                        <p>Admin/Owner</p>
                        <label className="switch">
                          <input name='owner' type="checkbox" onChange={event => this.checkBoxOnChange(event)} checked={officeId !== '' ? rights.owner : false} />
                          <span></span>
                        </label>
                      </Col>
                    </div>
                  </fieldset>

                </CardBody>
                <CardFooter>
                  <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                      {!this.state.formReadOnly &&
                        <div className="text-center">
                          <button type="submit" className="btn btn-info">Pas aan</button>
                        </div>
                      }
                    </Col>
                    <Col lg={3}>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </form>
          </div>
        </ContentWrapper>
      )
    } else {
      return (<p>loading...</p>)
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    officeId: ownProps.match.params.officeId,
    id: ownProps.match.params.id,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (creds, id, officeId) => dispatch(editUser(creds, id, officeId)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
  )(UserGegevens)