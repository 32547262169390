import React from 'react';
import ToggleState from '../Common/ToggleState';
import TriggerResize from '../Common/TriggerResize';
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const Header = (props) => {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <div className="brand-logo">
                                <img className="img-fluid" style={{height: 30}} src="/img/logo.png" alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                            <TriggerResize>
                                <ToggleState state="aside-collapsed">
                                    <p className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                        <em className="fas fa-bars"></em>
                                    </p>
                                </ToggleState>
                            </TriggerResize>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <p  className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </p>
                            </ToggleState>
                        </li>


                        { /* END User avatar toggle */ }
                        { /* START lock screen */ }
                        <li className="nav-item d-none d-md-block">
                        <p  className="nav-link" onClick={props.signOut}>
                                <em className="icon-lock"></em>
                            </p>
                        </li>
                        { /* END lock screen */ }
                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */ }
                        
                        { /* Fullscreen (only desktops) */ }
                        <li className="nav-item d-none d-md-block">
                            <a href="https://maxwiki.nl/knowledge-base/maxxus-medewerkersportal-wiki/" target="_blank" rel="noopener noreferrer"><i style={{color: "white", paddingRight: '50px'}}className="far fa-question-circle"></i></a>
                        </li>
                        { /* START Alert menu */ }
                        
         
                    </ul>
                    { /* END Right Navbar */ }

                    { /* START Search form */ }
                    <form className="navbar-form" role="search" action="search.html">
                       <div className="form-group">
                          <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                          <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                       </div>
                       <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }


const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }

export default connect(null, mapDispatchToProps)(Header);
