import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { editMeldingAction } from '../../../store/actions/meldingActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';
import { FormItem } from '../../DMComponents/FormComponents';
import firebase from '../../../config/fbConfig';
import { dtOptions4 } from '../../DMComponents/TableComponents'
import Datatable from '../../Tables/Datatable';

class NewLoginKlant extends Component {

  state = {
    melding: {
      meldingName: '',
      meldingPortal: false,
      meldingContent: '',
      meldingArray: []
    }
  }
  getData() {
    const db = firebase.firestore();
    const { meldingId } = this.props
    db.collection(`meldingenMaxxus`).doc(meldingId).get()
      .then((doc) => {
        var melding = doc.data()
        melding = { ...this.state.melding, ...melding }
        this.setState({ melding })
      })
      .catch(error => {
        console.log("Error getting documents: ", error);
      });
  }

  componentDidMount() {
    this.getData()
  }
  validateOnChange = event => {
    console.log('this.state on channge  wup', this.state)
    const input = event.target;
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      [form.name]: {
        ...this.state.melding,
        [input.name]: value
      }

    })

  }

  onSubmit = e => {
    e.preventDefault()
    //const form = e.target;
    //const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

    //const { errors } = FormValidator.bulkValidate(inputs)

    this.props.editMeldingAction(this.state, this.props.meldingId)
  }

  render() {
    const { melding } = this.state
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            <Link to={`/meldingen`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
          </div>
        </div>
        <Row>
          <div className="col-md-12">
            <form onSubmit={this.onSubmit} action="" name="melding">
              { /* START card */}
              <Card className="card-default">
                <CardBody>
                  <legend className="mb-4">Melding aanpassen</legend>
                  <fieldset className="last-child">
                    <FormItem
                      name="meldingName"
                      nameShow="Naam melding"
                      onChange={event => this.validateOnChange(event)}
                      value={this.state.melding.meldingName}
                      readOnly={this.state.formReadOnly}
                    />
                    <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                      <label className="col-md-2 col-form-label">Melding</label>
                      <Col md={8}>
                        <textarea
                          rows="16"
                          className="form-control"
                          name="meldingContent"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.melding.meldingContent}
                          readOnly={this.state.formReadOnly}
                        />
                      </Col>
                      <Col md={2}>
                      </Col>
                    </div>
                  </fieldset>
                </CardBody>
                <CardFooter className="text-center">
                  <button type="submit" className="btn btn-info">Melding aanpassen</button>
                </CardFooter>
                <CardBody>
                  {melding.emailArray &&
                    <Datatable options={dtOptions4}>
                      <table className="table w-100 table-striped">
                        <thead>
                          <tr>
                            <th className="sort-alpha">Naam</th>
                            <th className="sort-alpha">E-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {melding.emailArray.map(user => {
                            return (
                              <tr className="grade" key={user.id}>
                                <td>{user.firstName}{user.midName !== ' ' && ` ${user.midName} `}{user.lastName}</td>
                                <td>{user.email}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </Datatable>
                  }
                </CardBody>
              </Card>
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    logins: state.firestore.data.logins,
    meldingId: ownProps.match.params.meldingId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editMeldingAction: (loginProp, meldingId) => dispatch(editMeldingAction(loginProp, meldingId)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{
    collection: 'logins', orderBy: ['loginName']
  }])
)(NewLoginKlant)
