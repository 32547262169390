import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from '../Elements/Swal';
import Select from 'react-select';

/* 
optional: 
    type(std. value = text)
    onChange,
    readOnly

required: 
    value,
*/

export const FormItem = (props) => (
    <div className="form-group row align-items-center">
        <label className="col-md-2 col-form-label">{props.nameShow}</label>
        <Col  md={6}>
            <Input
                style={{marginBottom: "7px"}}
                type={props.type ? props.type : 'text'}
                name={props.name}
                onChange={props.onChange && props.onChange}
                value={props.value}
                readOnly={props.readOnly ? props.readOnly : false }
                placeholder={props.placeholder ? props.placeholder : null}
            />
        </Col>
        <Col md={4}>
        </Col>
    </div>
)

export const EditButton = (props) => (
  <Link to={props.to}><i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} className="icon-orange far fa-edit"></i></Link>
)

export const ShowButton = (props) => (
  <Link to={props.to}><i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} className="icon-orange fas fa-eye"></i></Link>
)

export const AddButton = (props) => (
  <Swal options={AddOptions} callback={props.callback}><i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} className="icon-orange fas fa-plus"></i></Swal>
)

export const DeleteButtonSwal = (props) => (
  <Swal options={AlertOptions} callback={props.callback}><i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} className="icon-orange far fa-trash-alt"></i></Swal>
)

export const DeleteButton = (props) => (
  <i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} onClick={props.onClick} className="icon-orange far fa-trash-alt"></i>
)

export const FunctionButton = (props) => (
  <button onClick={props.onClick} style={{height: "25px", padding: "3px"}} className="btn btn-info">{props.label}</button>
)
const AddOptions = {
  title: 'Weet je het zeker?',
  text: 'Er wordt nu een gebruiker toegevoegd',
  icon: 'warning',
  buttons: {
      cancel: {
          text: 'Nee, annuleer!',
          value: null,
          visible: true,
          className: "",
          closeModal: true
      },
      confirm: {
          text: 'Ja, nodig uit!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
      }
  }
}
const MeldingOptions = {
  title: "Weet je het zeker?",
  text: "U staat op het een melding toe te voegen",
  icon: "warning",
  buttons: {
    cancel: {
        text: 'Nee, annuleer!',
        value: null,
        visible: true,
        className: "",
        closeModal: true
    },
    confirm: {
        text: 'Ja, ga!',
        value: true,
        visible: true,
        className: "bg-danger",
        closeModal: true
    }
}
}
const AlertOptions = {
  title: "Weet je het zeker?",
  text: "U staat op het punt iets te verwijderen",
  icon: "warning",
  buttons: {
    cancel: {
        text: 'Nee, annuleer!',
        value: null,
        visible: true,
        className: "",
        closeModal: true
    },
    confirm: {
        text: 'Ja, verwijder!',
        value: true,
        visible: true,
        className: "bg-danger",
        closeModal: true
    }
}
}

const ConfirmTest = {
  title: "Weet je het zeker?",
  text: "U staat op het punt een test e-mail te versturen",
  icon: "warning",
  buttons: {
    cancel: {
        text: 'Nee, annuleer!',
        value: null,
        visible: true,
        className: "",
        closeModal: true
    },
    confirm: {
        text: 'Ja!',
        value: true,
        visible: true,
        className: "bg-danger",
        closeModal: true
    }
}
}



export const ConfirmButton = (props) => (
  <Swal options={MeldingOptions} callback={props.callback}><button className="btn btn-info">{props.label}</button></Swal>
)

export const ConfirmTestMailButton = (props) => (
  <Swal options={ConfirmTest} callback={props.callback}><button className="btn btn-info">{props.label}</button></Swal>
)


export const DeleteDocIcon = (props) => (
  <Swal options={AlertOptions} callback={props.callback}><i style={{cursor: 'pointer', marginLeft: '5px', marginRight: '5px'}} className="icon-orange far fa-trash-alt"></i></Swal>
)

export const DeleteDocButton = (props) => (
  <Swal options={AlertOptions} callback={props.callback}><button style={{marginLeft: '5px', marginRight: '5px'}} className="btn btn-danger">Delete</button></Swal>
)
const genderArray = [{label: "Man", value: "M"}, {label: "Vrouw", value: "V"}, {label: "Afdeling", value: "AFD"}, {label: "Niet van toepassing", value: "NVT"}]

export const GenderButton = (props) => (
  <Row>
    <label className="col-md-2 col-form-label">Aanhef</label>
    <Col md={6}>
    <Select
      defaultValue={genderArray.map(gender => gender.value === props.currentValue && {label: gender.label, value: gender.value})}
      name='select'
      options={genderArray}
      onChange={props.onChange}
    />
    </Col>
  </Row> 
  )

  export const DownloadButton = (props) => (
    <i style={{cursor: 'pointer', marginLeft: '5px', marginRight: '5px'}} onClick={props.onClick} className="icon-orange fas fa-download"></i>
  )