import React, { Component } from 'react'
import { Row, Col, Input, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { createLoginKlant } from '../../../store/actions/loginInfoActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import * as generator from 'generate-password';



class NewLoginKlant extends Component {

    state = {
      index: 10 ,
      url: 'https://'
    }

    validateOnChange = event => {
        const input = event.target;
        //const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;
            this.setState({
                [input.name]: value,
            })
        
    }
    handleChangeSelectMulti = (selectedOptionMulti, type) => {
      const { logins } = this.props;
      const password = generator.generate({
        length: 20,
        numbers: true, 
        symbols: true,
        exclude: ",;#<>"
      });

      this.setState({
        [type]: selectedOptionMulti.value,
        description: logins[selectedOptionMulti.value].description.stdValue ? logins[selectedOptionMulti.value].description.stdValue : '',
        adminLogin: logins[selectedOptionMulti.value].adminLogin.stdValue ? logins[selectedOptionMulti.value].adminLogin.stdValue : '',
        adminPassword: logins[selectedOptionMulti.value].adminPassword.stdValue ? logins[selectedOptionMulti.value].adminPassword.stdValue : password,
        extra1: logins[selectedOptionMulti.value].extra1.stdValue ? logins[selectedOptionMulti.value].extra1.stdValue : '',
        extra2: logins[selectedOptionMulti.value].extra2.stdValue ? logins[selectedOptionMulti.value].extra2.stdValue : '',
        index: logins[selectedOptionMulti.value].index.stdValue ? logins[selectedOptionMulti.value].index.stdValue : 30,
        lanIp: logins[selectedOptionMulti.value].lanIp.stdValue ? logins[selectedOptionMulti.value].lanIp.stdValue : '',
        url: logins[selectedOptionMulti.value].url.stdValue ? logins[selectedOptionMulti.value].url.stdValue : '',
        wanIp1: logins[selectedOptionMulti.value].wanIp1.stdValue ? logins[selectedOptionMulti.value].wanIp1.stdValue : '',
        wanIp2: logins[selectedOptionMulti.value].wanIp2.stdValue ? logins[selectedOptionMulti.value].wanIp2.stdValue : ''

    })}

    onSubmit = e => {
        e.preventDefault()
        this.props.createLoginKlant(this.state, this.props.officeId)
    }

    render() {
        const { logins, loginsArray, officeId } = this.props
        const { loginId } = this.state
        var values = ['loginName', 'description', 'category', 'adminLogin', 'adminPassword', 'userLogin', 'userPassword', 'url', 'wanIp1', 'wanIp2', 'lanIp', 'index', 'inbelNummer', 'pinCode', 'extra1', 'extra2', 'extra3', 'extra4', 'ssid1', 'ssidKey1', 'ssidInf1', 'ssid2', 'ssidKey2', 'ssidInf2', 'ssid3', 'ssidKey3', 'ssidInf3', 'ssid4', 'ssidKey4', 'ssidInf4']

        return (
          <ContentWrapper>
            <div className="content-heading">
              <div>
                <Link to={`/klant/${officeId}/logins`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
              </div>
            </div>
            <Row>
              <div className="col-md-12">
                <form onSubmit={this.onSubmit} action="" name="gegevens">
                  { /* START card */}
                  <Card className="card-default">
                    <CardBody>
                        <legend className="mb-4">Login gegevens</legend>
                        <fieldset>
                            <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                <label className="col-md-2 col-form-label">Login naam</label>
                                <Col md={6}>
                                  <Select
                                    name="loginId"  
                                    //value={this.state.loginId}
                                    onChange={(value) => this.handleChangeSelectMulti(value, 'loginId')}
                                    options={loginsArray}/>
                                </Col>
                                <Col md={4}>
                                </Col>
                            </div>
                        </fieldset>
                        <fieldset className="last-child">
                        {logins && loginId && logins[loginId] && values.map(index => {
                          if (index !== 'category' && index !== 'loginName' && logins[loginId] && logins[loginId][index] && logins[loginId][index].valueStatus === true){
                            if (logins[loginId][index].type === 'textBox') {
                              return (
                                <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                  <label className="col-md-2 col-form-label">{logins[loginId] && logins[loginId][index] && logins[loginId][index].name}</label>
                                  <Col md={6}>
                                    <textarea
                                      rows="4"
                                      className="form-control note-editor note-editor"
                                      onChange={this.validateOnChange}
                                      value={this.state[index]}
                                      name={index} />
                                  </Col>
                                </div>
                              )
                            } else {
                              return (
                                <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                  <label className="col-md-2 col-form-label">{logins[loginId] && logins[loginId][index] && logins[loginId][index].name}</label>
                                  <Col md={6}>
                                    <Input
                                      className="border-right-0"
                                      onChange={this.validateOnChange}
                                      value={this.state[index]}
                                      name={index} />
                                  </Col>
                                </div>
                              )
                            }
                          } else return null 
                        })}                                        
                      </fieldset>
                    </CardBody>
                    <CardFooter className="text-center">
                      <button type="submit" className="btn btn-info">Product toevoegen</button>
                    </CardFooter>
                  </Card>
                </form>
              </div>
            </Row>
          </ContentWrapper>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    const statusError = state.logins.statusError;
    const statusSucces = state.logins.statusSucces;
    var loginsArray =  state.firestore.ordered && 
                  state.firestore.ordered.logins && 
                  (state.firestore.ordered.logins.map(login => ({value: login.id, label: login.loginName.valueStatus}))).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    var logins = state.firestore.data.logins
    return {
        statusError,
        statusSucces,
        logins,
        loginsArray,
        officeId: ownProps.match.params.officeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createLoginKlant: (loginProp, officeId) => dispatch(createLoginKlant(loginProp, officeId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'logins'
    }])
)(NewLoginKlant)
