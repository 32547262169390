import authReducer from './authReducer'
import loginInfoReducer from './loginInfoReducer'
import SubscriptionReducer from './SubscriptionReducer';
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'


const appReducer = combineReducers({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  logins: loginInfoReducer,
  subscriptions: SubscriptionReducer

});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
