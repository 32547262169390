import firebase from '../../config/fbConfig';

let onSuccess = (message) => {
  return {
    type: 'SUCCESS',
    payload: message
  }
}

let resetState = () => {
  return {
    type: "RESET_STATUS"
  }
}

function onError(error) {
  return {
    type: 'ERROR',
    error
  }
}

export const newMeldingAction = (state) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    let userArray = []

    try {
      let wait1 = state.melding.loginId.map(async login => {
        const offices = await firestore.collection('loginsOffices').where('loginId', '==', login.value).get()
        let wait2 = offices.docs.map(async office => {
          const users = await firestore.collection(`offices/${office.data().officeId}/users`).get()
          users.docs.map(user => {
            if (user.data().emailMeldingen && user.data().meldingen) {
              userArray.push({
                uid: user.id,
                email: true,
                melding: true
              })
            } else if (user.data().emailMeldingen && !user.data().meldingen) {
              userArray.push({
                uid: user.id,
                email: true,
                melding: false
              })
            } else if (!user.data().emailMeldingen && user.data().meldingen) {
              userArray.push({
                uid: user.id,
                email: false,
                melding: true
              })
            }
            return null
          })
          return null
        })
        await Promise.all(wait2)
        return null
      })
      await Promise.all(wait1)

      var newArray = [];
      for (var i = 0; i < userArray.length; i++) {
        // eslint-disable-next-line
        if (!newArray.some(e => e.uid === userArray[i].uid)) {
            newArray.push(userArray[i])
        }
      }
    
      
      let meldingId = await firestore.collection('meldingenMaxxus').add({
        loginId: state.melding.loginId,
        meldingContent: state.melding.meldingContent,
        meldingName: state.melding.meldingName,
      })
      
      
      newArray.map(async user => {
        await firestore.collection('meldingen').add({
          ...user,
          date: new Date(),
          meldingId: meldingId.id
        })
      })
      

      dispatch(onSuccess('Nieuwe melding toegevoegd'))
      return await setTimeout(() => { dispatch(resetState()) }, 3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) }, 10000)
    }
  }
}

export const testMelding = (state, profile) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    let emailUserArray = []
    try {
      let wait1 = state.melding.loginId.map(async login => {
        const offices = await firestore.collection('loginsOffices').where('loginId', '==', login.value).get()
        let wait2 = offices.docs.map(async office => {
          const users = await firestore.collection(`offices/${office.data().officeId}/users`).get()
          users.docs.map(user => {
            if (user.data().emailMeldingen && user.data().meldingen) {
              emailUserArray.push(user.id)
            } else if (user.data().emailMeldingen && !user.data().meldingen) {
              emailUserArray.push(user.id)
            }
            return null
          })
          return null
        })
        await Promise.all(wait2)
        return null
      })
      await Promise.all(wait1)

      const UniqueEmailUserArray = [...new Set(emailUserArray)]

      let emailArray = UniqueEmailUserArray.map(async (userId) => {
        let userRaw = await firestore.collection('users').doc(userId).get()
        let userDoc = userRaw.data()
        return { uid: userId, firstName: userDoc ? userDoc.firstName : "", midName: userDoc ? userDoc.midName : "", lastName: userDoc ? userDoc.lastName : "", email: userDoc ? userDoc.email : "" }
      })

      let array = await Promise.all(emailArray)

      await firestore.collection('meldingenTest').add({
        uid: 'test',
        loginId: state.melding.loginId,
        meldingContent: state.melding.meldingContent,
        date: new Date(),
        test: true,
        emailArray: array,
        emailTest: state.melding.emailTest,
        profile,
        meldingName: state.melding.meldingName,
      })


      dispatch(onSuccess('Test melding toegevoegd'))

      return await setTimeout(() => { dispatch(resetState()) }, 3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) }, 10000)
    }
  }
}



export const editMeldingAction = (state, meldingId) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();

    try {
      await firestore.collection('meldingenMaxxus').doc(meldingId).update(state.melding)
      dispatch(onSuccess('Melding aangepast'))
      return await setTimeout(() => { dispatch(resetState()) }, 3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) }, 10000)
    }
  }
}

