import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { updateSubscription } from '../../../store/actions/SubscriptionActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import firebase from '../../../config/fbConfig';
import { withRouter } from 'react-router';
import moment from 'moment'
import FormValidator from '../../Forms/FormValidator.js';


class EditSubscription extends Component {


	state = {
		bewerken: false,
		passwordShow: false
}

getData() {
		const db = firebase.firestore();
		const { officeId, subscriptionId } = this.props
		db.collection(`offices/${officeId}/subscriptions`).doc(subscriptionId).get()
				.then((doc) => {
						var subscription = doc.data()
						this.setState({ subscription: subscription })
				})
				.catch(error => {
						console.log("Error getting documents: ", error);
				});
}

componentDidMount() {
		this.getData()
}

validateOnChange = event => {
		const input = event.target;
		const form = input.form
		const value = input.type === 'checkbox' ? input.checked : input.value;
		console.log('clg', this.state.subscription)
		const result = FormValidator.validate(input);

		this.setState({
				[form.name]: {
						...this.state[form.name],
						[input.name]: value,
				},
				errors: {
						...this.state[form.name].errors,
						[input.name]: result
				}
		});

}

hasError = (formName, inputName, method) => {
		return this.state[formName] &&
				this.state[formName].errors &&
				this.state[formName].errors[inputName] &&
				this.state[formName].errors[inputName][method]
}

onSubmit = e => {
		e.preventDefault()
		this.setState({
				...this.state,
				bewerken: false,
		})
	

		this.props.updateSubscription(this.props.subscriptionId, this.props.officeId, this.state.subscription)
}	

handleBewerken = () => {
		this.setState({
				bewerken: !this.state.bewerken
		})
}

handleTogglePassword = () => {
		this.setState({
				passwordShow: !this.state.subscription.passwordShow
		})
}

goBack = () => {
		this.props.history.goBack()
}

    render() {
        const { bewerken } = this.state
        if (this.state.subscription) {
            return (
                <ContentWrapper>
                    <div className="content-heading">
                        <div>Abonnement veranderen
                            <small>Abonnement gegevens van de klant veranderen</small><br />
                            <button className="btn btn-info" onClick={this.goBack}><i className="fas fa-arrow-left"></i>  Terug</button>  <button onClick={this.handleBewerken} className={bewerken === true ? "btn btn-danger" : "btn btn-info"}>Bewerken</button>
                        </div>
                    </div>
                    <Row>
                        <div className="col-md-12">
                            {bewerken === false ?
                                <div>
																{ /* START card */}
																<Card className="card-default">
																		<CardBody>
																				<legend className="mb-4">Abonnement gegevens</legend>																						
																				<fieldset className="last-child">
																				<div style={{ paddingBottom: '10px' }} className="form-group row align-items-center">
																						<label className="col-md-2 col-form-label">Aantal</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly
																										value={this.state.subscription.amount}
																										name="amount" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">Beschrijving voor klant</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.desciptionCustomer}
																										name="desciptionCustomer" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">start- en einddatum</label>
																						<Col md={3}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.startDate}
																										name="startDate" />
																						</Col>
																						<Col md={3}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.endDate}
																										name="endDate" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">Hoofd nummer</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.mainNumber}
																										name="mainNumber" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">Nummerblok</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.numberSeries}
																										name="numberSeries" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">Aankoopprijs</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.purchasePrice}
																										name="purchasePrice" />
																						</Col>
																						<Col md={4}></Col>
																						<label className="col-md-2 col-form-label">Verkoopprijs</label>
																						<Col md={6}>
																								<input
																										rows="4"
																										className="form-control"
																										readOnly																										
																										value={this.state.subscription.sellingPrice}
																										name="sellingPrice" />
																						</Col>
																						<Col md={4}></Col>
																				</div>                                       
																				</fieldset>
																		</CardBody>
																		
																		<CardFooter className="text-left">
																			{this.state.subscription && this.state.subscription.lastUpdate && this.props.users[this.state.subscription.updateBy]? "Laatste update op " + moment(this.state.subscription.lastUpdate.seconds * 1000).format('DD-MM-YYYY HH:mm') + " door " + this.props.users[this.state.subscription.updateBy].firstName + " " + this.props.users[this.state.subscription.updateBy].lastName : "Laatste update is niet bekend"}
																		</CardFooter>
																</Card>
														</div>
                                :
                            <form onSubmit={this.onSubmit} action="" name="subscription">
                            { /* START card */}
                            <Card className="card-default">
                                <CardBody>
                                    <legend className="mb-4">Abonnement gegevens</legend>
                                    <fieldset className="last-child">
                                    <div style={{ paddingBottom: '10px' }} className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Aantal</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.amount}
                                                name="amount" />
                                        </Col>
																				<Col md={4}></Col>
                                        <label className="col-md-2 col-form-label">Beschrijving voor klant</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.desciptionCustomer}
                                                name="desciptionCustomer" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">start- en einddatum</label>
                                        <Col md={3}>
                                            <input
																								rows="4"
																								type="date"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.startDate}
                                                name="startDate" />
                                        </Col>
																				<Col md={3}>
                                            <input
																								rows="4"
																								type="date"
																								className="form-control"
																								onChange={this.validateOnChange}
                                                value={this.state.subscription.startDate}
                                                name="endDate" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Hoofd nummer</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.mainNumber}
                                                name="mainNumber" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Nummerblok</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.numberSeries}
                                                name="numberSeries" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Aankoopprijs</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.purchasePrice}
                                                name="purchasePrice" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Verkoopprijs</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.subscription.sellingPrice}
                                                name="sellingPrice" />
                                        </Col>
																				<Col md={4}></Col>
                                    </div>                                       
                                    </fieldset>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <button type="submit" className="btn btn-info">Abonnement toevoegen</button>
                                </CardFooter>
                            </Card>
                        </form>
                            }
                        </div>
                    </Row>
                </ContentWrapper>
            )
        } else {
            return null
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    const users = state.firestore.data.users;
    const subscriptions = state.firestore.data.subscriptions;
    const subscriptionId = ownProps.match.params.subscriptionId;
    const officeId = ownProps.match.params.officeId;

    return {
        users,
        subscriptions,
        auth: state.firebase.auth,
        subscriptionId,
        officeId,
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
			updateSubscription: (loginId, officeId, loginProp) => dispatch(updateSubscription(loginId, officeId, loginProp)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'subscriptions' }
    ])
)(EditSubscription)
