import firebase from '../../config/fbConfig';

let onSuccess = (message) => {
    return {
        type: 'SUCCESS',
        payload: message
    }
}

let resetState = () => {
    return {
        type: "RESET_STATUS"
    }
}

function onError(error) {
    return {
        type: 'ERROR',
        error
    }
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}




export const LoginToCsvOffices = (loginId) => {
    return async (dispatch) => {
        const firestore = firebase.firestore();
        try {
            let officeArray = []
            let wait1 = loginId.map(async login => {
                const offices = await firestore.collection('loginsOffices').where('loginId', '==', login.value).get()
                let wait2 = offices.docs.map(async office => {

                    const officeId = office.data().officeId;
                    const officeDoc = await firestore.collection('offices').doc(officeId).get();

                    const doc = {
                        officeId,
                        debnr: officeDoc.data().debnr,
                        officeName: officeDoc.data().officeName,
                    }
                    officeArray.push(doc)
                    return null;
                })
                await Promise.all(wait2)
                return null
            })
            await Promise.all(wait1)

            var headers = {
                officeId: 'OfficeId'.replace(/,/g, ''),
                debnr: "Debiteur nummer",
                officeName: "OfficeName",
            };
            var fileTitle = 'OfficeExport';
            const UniqueofficeArray = [...new Set(officeArray)]

            exportCSVFile(headers, UniqueofficeArray, fileTitle);

            dispatch(onSuccess('Download'))
            return await setTimeout(() => { dispatch(resetState()) }, 3000)
        }
        catch (error) {
            dispatch(onError(error))
            return await setTimeout(() => { dispatch(resetState()) }, 10000)
        }
    }
}

export const LoginToCsvUsers = (loginId) => {
    return async (dispatch, getState) => {
        const firestore = firebase.firestore();
        let emailUserArray = []
        let meldingenUserArray = []
        try {
            let wait1 = loginId.map(async login => {
                const offices = await firestore.collection('loginsOffices').where('loginId', '==', login.value).get()
                let wait2 = offices.docs.map(async office => {
                    const users = await firestore.collection(`offices/${office.data().officeId}/users`).get()
                    users.docs.map(user => {
                        if (user.data().emailMeldingen && user.data().meldingen) {
                            emailUserArray.push(user.id)
                            meldingenUserArray.push(user.id)
                        } else if (user.data().emailMeldingen && !user.data().meldingen) {
                            emailUserArray.push(user.id)
                        } else if (!user.data().emailMeldingen && user.data().meldingen) {
                            meldingenUserArray.push(user.id)
                        }
                        return null
                    })
                    return null
                })
                await Promise.all(wait2)
                return null
            })
            await Promise.all(wait1)

            const UniqueEmailUserArray = [...new Set(emailUserArray)]
            //const UniqueMeldingenUserArray = [...new Set(meldingenUserArray)]

            let emailArray = UniqueEmailUserArray.map(async (userId) => {
                let userRaw = await firestore.collection('users').doc(userId).get()
                let userDoc = userRaw.data()
                return { uid: userId, firstName: userDoc ? userDoc.firstName : "", midName: userDoc ? userDoc.midName : "", lastName: userDoc ? userDoc.lastName : "", email: userDoc ? userDoc.email : "" }
            })

            let array = await Promise.all(emailArray)

            var headers = {
                userId: 'userId'.replace(/,/g, ''),
                firstName: "Voornaam",
                midName: "Tussenvoegsel",
                lastName: "Achternaam",
                email: "Email",
            };
            var fileTitle = 'UserExport';

            exportCSVFile(headers, array, fileTitle);
            dispatch(onSuccess("Download"))
            return await setTimeout(() => { dispatch(resetState()) }, 3000)
        }
        catch (error) {
            dispatch(onError(error))
            return await setTimeout(() => { dispatch(resetState()) }, 10000)
        }
    }
}
