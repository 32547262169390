import firebase from 'firebase';

let onSuccess = (message) => {
  return {
      type: 'SUCCESS',
      payload: message 
    }
}

let resetState = () => {
  return {
    type: "RESET_STATUS"
  }
}

function onError(error) {
  return {
    type: 'ERROR',
    error 
  }
}

export const getDownloadUrl = (path, description, type) => {
  return async (dispatch) => {
    var storage = firebase.storage();
    var storageRef = storage.refFromURL(`gs://documentenoffices/${path}`);
                
    storageRef.getDownloadURL().then(function(url) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.open('GET', url, true);
      xhr.send();
      xhr.onload = function(e) {
        if (this.status === 200) {
            // Create a new Blob object using the 
            //response data of the onload object
            var blob = new Blob([this.response], {type: 'image/pdf'});
            //Create a link element, hide it, direct 
            //it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob 
            //and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            console.log(url)
            a.download = description + '.' + type.split("/")[1];
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
            dispatch(onSuccess('Download succesvol'))
            return setTimeout(() => { dispatch(resetState()) } ,3000)

        }else{
          dispatch(onError({error: {message: 'Download mislukt'}}))
          return setTimeout(() => { dispatch(resetState()) } ,10000)  //deal with your error state here
        }
      };
    
  }).catch(function(error) {
      dispatch(onError(error))
      return setTimeout(() => { dispatch(resetState()) } ,10000) 
  });
  }
}

export const uploadSucces = (type, filename, beschrijving, officeId) => {
  return async (dispatch, getState, { getFirestore }) => {
      const firestore = getFirestore();
      try {
        let metadata = await firebase.app().storage("gs://documentenoffices").ref(`${officeId}/${type}`).child(filename).getMetadata()
        await firestore.collection(`officesFiles`).add(
          { type, 'officeId': officeId, filePath: `${officeId}/${type}/${filename}`, 'description': filename, 'bijlage': metadata.fullPath, 'fileSize': metadata.size, 'contentType': metadata.contentType, 'date': new Date(metadata.updated), fileRef: "NaN" }
        )
        dispatch({type: 'SUCCESS', payload: "Upload success"})
        return setTimeout(() => {
          dispatch({
            type: 'RESET_STATUS'
          })
        }, 5000)
      }
      catch(error) {
        dispatch({type: 'ERROR', error})
        return setTimeout(() => {
          dispatch({
            type: 'RESET_STATUS'
          })
        }, 5000)
      }
  }
}
export const uploadError = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({type: 'ERROR', err: {message: "Upload mislukt"}})
      return setTimeout(() => {
        dispatch({
          type: 'RESET_STATUS'
        })
      }, 5000)
  }
}
