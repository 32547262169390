import React, { Component } from 'react';
import { resetPassword } from '../../store/actions/authActions'
import { Alert} from 'reactstrap';
import { connect } from 'react-redux';
import FormValidator from '../Forms/FormValidator.js';

const year = new Date().getFullYear()

class Recover extends Component {
state = {
    email: ''
}
onSubmit = e => {
    const form = e.target;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
        
            ...this.state,
            errors
        
    });

    console.log(hasError ? 'Form has errors. Check!' : this.props.resetPassword(this.state))

    e.preventDefault()
}
  onChange = (event) => {
  const input = event.target;
  const value = input.type === 'checkbox' ? input.checked : input.value;
  const result = FormValidator.validate(input);
    this.setState({
      ...this.state,
      [input.name]: value,
      errors: {
          ...this.state,
          [input.name]: result
      }
    });  
  }

  hasError = (inputName, method) => {
    return  this.state &&
            this.state.errors &&
            this.state.errors[inputName] &&
            this.state.errors[inputName][method]
  }
    render() {
        const {recoverSuccess, recoverError} = this.props;
        return (
            <div className="block-center mt-4 wd-xl">
                {/* START card */}
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                      <img className="block-center rounded" src="img/logo.png" alt="Logo"/>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">PASSWORD RESET</p>
                        <form onSubmit={this.onSubmit} name="email">
                            <p className="text-center">Geef uw e-mailadres op om een wachtwoordreset link te ontvangen.</p>
                            <div className="form-group">
                                <label className="text-muted" htmlFor="resetInputEmail1">Email address</label>
                                <div className="input-group with-focus">
                                    <input 
                                    onChange={this.onChange} 
                                    className="form-control border-right-0" 
                                    name="email" 
                                    type="email" 
                                    placeholder="Enter email"
                                    invalid={this.hasError('formLogin','email','required')||this.hasError('formLogin','email','email')}
                                    data-validate='["required", "email"]' 
                                    autoComplete="off"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                            <button className="btn btn-danger btn-block" type="submit">Reset</button><br />
                            { this.hasError('formLogin','email','required') && <span className="invalid-feedback">Field is required</span> }
                            { this.hasError('formLogin','email','email') && <span className="invalid-feedback">Field must be valid email</span> }
                            {recoverError? <Alert color="danger">{recoverError}</Alert>:null}
                            {recoverSuccess? <Alert color="success">{recoverSuccess}</Alert>:null}
                        </form>
                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>{year}</span>
                    <span className="mx-2">-</span>
                    <span>MaXXus Communications</span>
                    <br/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
      recoverError: state.auth.recoverError,
      recoverSuccess: state.auth.recoverSuccess
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (creds) => dispatch(resetPassword(creds))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Recover)
