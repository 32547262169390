import React from 'react';
import { connect } from 'react-redux'
import { newContactPersoon } from '../../../../store/actions/authActions'
import 'react-datetime/css/react-datetime.css';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { CardBody, CardFooter, Row, Col, Card } from 'reactstrap';
import ContentWrapper from '../../../Layout/ContentWrapper';
import { FormItem, GenderButton } from '../../../DMComponents/FormComponents';
import { Link } from 'react-router-dom';

class UserGegevens extends React.Component {

    state = {
      newUser: {
        admin: '',
        status: 'pending',
        doorkiesNr: '',
        email: '',
        firstName: '',
        lastName: '',
        midName: '',
        gsmnummer: '',
        male: false,
        gender: "AFD"
      }
    }
    
    validateOnChange = event => {
      const input = event.target;
      const form = input.form
      const value = input.type === 'checkbox' ? input.checked : input.value;

      this.setState({
        [form.name]: {
            ...this.state[form.name],
            [input.name]: value,
        }
      })
    }

    handleGenderSelect = (selectedOption, formName, type) => {
      this.setState({
        [formName]: {
              ...this.state[formName],
              [type]: selectedOption.value,
        },
      })
    }


    onSubmit = e => {
      e.preventDefault()
      const form = e.target;
      this.setState({
        [form.name]: {
            ...this.state[form.name]  
        }
      });
      this.props.newContactPersoon(this.state, this.props.id, this.props.token)
    }

    render() {
      return (
        <ContentWrapper>
          <div className="content-heading">
              <div>Contactpersoon toevoegen<br /><br />
                  <Link to={`/klant/${this.props.id}/contactpersonen`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
              </div>
          </div>
            <div className="col-md-12">
              <form onSubmit={this.onSubmit} action="" name="newUser">
                <Card className="card-default">
                  <CardBody>
                    <legend className="mb-4">Persoonsgegevens admin/owner</legend>
                      <fieldset>
                      <FormItem
                          name= "email"
                          nameShow= "E-mailadres"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser['email']}
                          readOnly={this.state.formReadOnly}  
                      />
                      <FormItem
                          name= "firstName"
                          nameShow= "Voornaam"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser['firstName']}
                          readOnly={this.state.formReadOnly}  
                      />
                      <FormItem
                          name= "midName"
                          nameShow= "Tussenvoegsel"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser['midName']}
                          readOnly={this.state.formReadOnly}  
                      />
                      <FormItem
                          name= "lastName"
                          nameShow= "Achternaam"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser['lastName']}
                          readOnly={this.state.formReadOnly}  
                      />
                      <FormItem
                          name= "gsmnummer"
                          nameShow= "Mobiele nummer"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser['gsmnummer']}
                          readOnly={this.state.formReadOnly}  
                      />
                      <FormItem
                          name= "doorkiesNr"
                          nameShow= "Doorkies nummer"
                          onChange={event => this.validateOnChange(event)}
                          value={this.state.newUser.doorkiesNr}
                          readOnly={this.state.formReadOnly}  
                      />
                      <GenderButton
                        currentValue={this.state.newUser.gender}
                        onChange={value => this.handleGenderSelect(value, 'newUser', 'gender')}
                      />    
                    </fieldset>
                    </CardBody>
                    <CardFooter>
                      <Row>
                          <Col lg={3}></Col>
                          <Col lg={6}>
                          {!this.state.formReadOnly &&
                              <div className="text-center">
                                  <button type="submit" className="btn btn-info">Voeg toe</button>
                              </div>
                          }
                          </Col>
                          <Col lg={3}>
                          </Col>
                      </Row>
              </CardFooter>
            </Card>   
          </form>
          </div>
        </ContentWrapper>
      )
    }

}


const mapStateToProps = (state, ownProps) => {
  const token = state.firebase.auth.stsTokenManager.accessToken
  return {
    id: ownProps.match.params.id,
    token
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    newContactPersoon: (creds, office, token) => dispatch(newContactPersoon(creds, office, token)),
  }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([])
)(UserGegevens)