import React from 'react';
import { connect } from 'react-redux'
import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'
import { Alert } from 'reactstrap'

const SuccessMessage = ({message}) => (
    <Alert
    style={{position: "fixed", bottom: "20px", right: "20px", zIndex: "1000", marginRight: "20px"}}
    color="success"
    >
    {message}
    </Alert>
    
    
)

const ErrorMessage = ({message}) => (
    <Alert
    style={{position: "fixed", bottom: "20px", right: "20px", zIndex: "1000", marginRight: "20px"}}
    color="warning"
    >
    {message}
    </Alert>
    
)


const Base = props => (
    <div className="wrapper">
        <Header />

        <Sidebar />

        <Offsidebar />

        <section className="section-container">
            { props.children }
            {props.successMessage && <SuccessMessage message={props.successMessage}/>}
            {props.errorMessage && <ErrorMessage message={props.errorMessage}/>}
        </section>
        <Footer />


    </div>
)

const mapStateToProps = (state) => {
    return {
        successMessage: state.auth.successMessage,
        errorMessage: state.auth.errorMessage,
    }
}

export default
    connect(mapStateToProps, null)(Base)

