import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import Login from './components/Pages/Login';
import Recover from './components/Pages/Recover';
import Action from './components/Pages/PageAction';

import DashboardV1 from './components/MaxxusMedewerker/Dashboard/DashboardV1';
import PrivateRoute from './components/Auth/PrivateRoute.js';
import LoginInfo from './components/MaxxusAdmin/LoginInfo/LoginInfo';
import LoginInfoDetails from './components/MaxxusAdmin/LoginInfo/LoginInfoDetails';

//Subscriptions components admin
import Subscriptions from './components/MaxxusAdmin/Subscriptions/Subscriptions'
import SubscriptionDetails from './components/MaxxusAdmin/Subscriptions/SubscriptionsDetails'

//Subscriptions components medewerker
import EditSubscription from './components/MaxxusMedewerker/Subscriptions/EditSubscription';
import MenuSubscriptions from './components/MaxxusMedewerker/Subscriptions/MenuSubscriptions';
import NewSubscription from './components/MaxxusMedewerker/Subscriptions/NewSubscription';

//Documents 
import MenuDocuments from './components/MaxxusMedewerker/Documenten/MenuDocuments'

// Logins components medewerker
import MenuLogins from './components/MaxxusMedewerker/Logins/MenuLogins';
import NewLoginKlant from './components/MaxxusMedewerker/Logins/NewLogin';
import EditLogins from './components/MaxxusMedewerker/Logins/EditLogins';

// Logins components medewerker
import Klanten from './components/MaxxusMedewerker/Gegevens/ListKlanten';
import MenuGegevens from './components/MaxxusMedewerker/Gegevens/MenuGegevens';

// Nieuwe klant toevoegen
import NewKlant from './components/MaxxusMedewerker/Gegevens/NewKlant';
import ListMeldingen from './components/MaxxusMedewerker/Meldingen/ListMeldingen';

//Meldingen
import NewMelding from './components/MaxxusMedewerker/Meldingen/NewMelding';
import EditMelding from './components/MaxxusMedewerker/Meldingen/EditMelding';

//contactpersonen klantenkaart
import NewContactPersoon from './components/MaxxusMedewerker/ContactPersonen/KlantKaart/NewContactPersoon';
import EditContactPersoon from './components/MaxxusMedewerker/ContactPersonen/KlantKaart/EditContactPersoon';
import AdminContactPersonen from './components/MaxxusMedewerker/ContactPersonen/KlantKaart/MenuContactPersonen';

//contactpersonen sidebar
import ListContactPersonen from './components/MaxxusMedewerker/ContactPersonen/SideBar/ListContactPersonen';
import EditContactPersoon2 from './components/MaxxusMedewerker/ContactPersonen/SideBar/EditContactPersoon';
import EditAccessContactPersoon from './components/MaxxusMedewerker/ContactPersonen/SideBar/EditAccessContactPersoon';
import EditAccessContactPersoon2 from './components/MaxxusMedewerker/ContactPersonen/KlantKaart/EditAccessContactPersoon';
import Error500 from './components/Pages/Error500';
import ListLogins from './components/MaxxusAdmin/Logins/ListLogins';
import LoginToCsv from './components/MaxxusMedewerker/LoginToCsv';



const listofPages = [
    '/login',
    '/recover',
    '/lock',
    '/notfound',
    '/error500',
    '/maintenance',
    '/verifyPhone',
    '/action'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInUp'
    //      'rag-fadeInDown'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    //      'rag-fadeInUpBig'
    //      'rag-fadeInDownBig'
    //      'rag-fadeInRightBig'
    //      'rag-fadeInLeftBig'
    //      'rag-zoomBackDown'
    const animationName = 'rag-fadeIn'

    if(listofPages.indexOf(location.pathname) > -1 || location.pathname.split('/')[1] === 'register' ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Switch location={location}>
                    <Route path="/login" component={Login}/>
                    <Route path="/recover" component={Recover}/>
                    <Route path="/error500" component={Error500}/>
                    <Route path="/action" component={Action} />
                </Switch>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
              <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                      <Switch location={location}>
                        <PrivateRoute path="/dashboard" exact component={DashboardV1} />
                        <PrivateRoute path='/klanten' component={Klanten} />
                        <PrivateRoute path='/klantproduct/:officeId/:loginId' component={EditLogins} />
                        <PrivateRoute path='/logindetails' component={LoginInfo} />
                        <PrivateRoute path='/logindetail/:loginId' component={LoginInfoDetails} />
                        <PrivateRoute path='/all-logins' component={ListLogins} />
                        <PrivateRoute path='/newloginklant/:officeId' component={NewLoginKlant} />
                        <PrivateRoute path='/newsubscriptionklant/:officeId' component={NewSubscription} />
                        <PrivateRoute path='/newklant' component={NewKlant} />
                        <PrivateRoute path='/klant/:id/gegevens' component={MenuGegevens} />
                        <PrivateRoute path='/klant/:id/logins' component={MenuLogins} />
                        <PrivateRoute path='/klant/:id/contactpersonen' component={AdminContactPersonen} />
                        <PrivateRoute path='/klant/:id/subscriptions' component={MenuSubscriptions} />
                        <PrivateRoute path='/klant/:id/documents' component={MenuDocuments} />
                        <PrivateRoute path='/editsubscription/:officeId/:subscriptionId' component={EditSubscription} />
                        <PrivateRoute path='/meldingen' component={ListMeldingen} />
                        <PrivateRoute path='/subscriptions' component={Subscriptions} />
                        <PrivateRoute path='/subscription/:subscriptionId' component={SubscriptionDetails} />
                        <PrivateRoute path='/newmelding' component={NewMelding} />
                        <PrivateRoute path='/editmelding/:meldingId' component={EditMelding} />
                        <PrivateRoute path='/newcontactpersoon/:id' component={NewContactPersoon}/>
                        <PrivateRoute path='/editcontactpersoon/:officeId/:id' component={EditContactPersoon} />
                        <PrivateRoute path='/contactpersonen' component={ListContactPersonen} />
                        <PrivateRoute path='/editcontactpersoon2/:id' component={EditContactPersoon2} />
                        <PrivateRoute path='/editaccesscontactpersoon/:id' component={EditAccessContactPersoon} />
                        <PrivateRoute path='/editaccesscontactpersoon2/:officeId/:id' component={EditAccessContactPersoon2} />
                        <PrivateRoute path='/logintocsv' component={LoginToCsv} />
                      <Redirect to="/dashboard" />
                      </Switch>
                    </div>
                </CSSTransition>
              </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);

