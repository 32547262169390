import React from 'react';
import { connect } from 'react-redux'
import { verifyUser } from '../../../store/actions/authActions'
import FormValidator from '../../Forms/FormValidator';
import 'react-datetime/css/react-datetime.css';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import firebase from '../../../config/fbConfig';
import {
    CardFooter,
    Row,
    Col,
    CardBody,
    Input,
} from 'reactstrap';

class UserGegevens extends React.Component {

    state = {}

    getDataInfo(id) {
      const db = firebase.firestore();
      db.collection('offices').doc(id).get()
        .then(doc => {
          this.setState({
              vestigingen: doc.data(),
              uid: doc.id
          })
        });
    }

    componentDidMount() {
      this.getDataInfo(this.props.id)
    }

    validateOnChange = event => {
      const input = event.target;
      const form = input.form
      const value = input.type === 'checkbox' ? input.checked : input.value;

      this.setState({
        [form.name]: {
            ...this.state[form.name],
            [input.name]: value,
        }
      })
    }

    VerifyOnChange = (event) => {
      console.log(this.state)
      const input = event.target;
      const form = input.form
      const value = input.type === 'checkbox' ? input.checked : input.value;

      this.setState({
        [form.name]: {
          ...this.state[form.name],
          [input.name]: value
        }
      })
    }

    

    validateOnChange = event => {
        console.log(this.state)

        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        this.setState({

            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
            }
        })
    }

    onChangeMasked = e => {
        const input = e.target;
        const form = input.form

        this.setState({

            [form.name]: {
                ...this.state[form.name],
                [e.target.name]: e.target.value

            }
        });
    }

    selectOnChange = (event) => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                gender: value
            }
        })
    }


    onSubmit = e => {
        e.preventDefault()
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        this.props.verifyUser(this.state)

    }
    toggleConfirm = () => {
        this.setState({
            orderConfirm: !this.state.orderConfirm,

        });
    }
    handleDelete = () => {
        this.props.deleteUser(this.state.uid)
    }
    /* Simplify error check */


    render() {
        // Usse t function instead of Trans component
        if (this.state.vestigingen) {
            return (
                this.props.bewerken === true ? 
                <form onSubmit={this.onSubmit} action="" name="vestigingen">
                    <div className="col-md-12">
                        <CardBody>
                            <legend className="mb-4">Bedrijfsgegevens</legend>
                            <fieldset>
                                <div className="form-group row align-items-center">
                                    <label className="col-md-2 col-form-label">Vestigingsnaam</label>
                                    <Col md={6}>
                                        <Input type="text"
                                            name="officeName"
                                            onChange={this.validateOnChange}
                                            value={this.state.vestigingen.officeName}
                                        />
                                    </Col>
                                    <Col md={4}>
                                    </Col>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label className="col-md-2 col-form-label">Telefoonnummer</label>
                                    <Col md={6}>
                                        <Input type="text"
                                            name="officeName"
                                            onChange={this.validateOnChange}
                                            value={this.state.vestigingen.telnr}
                                        />
                                    </Col>
                                    <Col md={4}>
                                    </Col>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label className="col-md-2 col-form-label">Debiteurnummer</label>
                                    <Col md={6}>
                                        <Input type="text"
                                            name="officeName"
                                            onChange={this.validateOnChange}
                                            value={this.state.vestigingen.debnr}
                                        />
                                    </Col>
                                    <Col md={4}>
                                    </Col>
                                </div>
                            </fieldset>
                                <fieldset>
                                    <div className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label"> Bezoekadres</label>
                                        <Col md={6}>
                                            <Input type="text"
                                                name="bezoekAdres"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.bezoekAdres}
                                            />
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                    <div style={{ paddingTop: '10px' }} className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Bezoekpostcode en -plaats</label>
                                        <Col md={3}>
                                            <Input type="text"
                                                name="bezoekPc"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.bezoekPc}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input type="text"
                                                name="bezoekPlaats"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.bezoekPlaats}
                                            />
                                        </Col>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label"> Postadres</label>
                                        <Col md={6}>
                                            <Input type="text"
                                                name="postAdres"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.postAdres}
                                            />
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                    <div style={{ paddingTop: '10px' }} className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Postpostcode en -plaats</label>
                                        <Col md={3}>
                                            <Input type="text"
                                                name="postPc"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.postPc}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input type="text"
                                                name="postPlaats"
                                                onChange={this.validateOnChange}
                                                value={this.state.vestigingen.postPlaats}
                                            />
                                        </Col>
                                    </div>
                                </fieldset>
                        </CardBody>
                    </div>
                        <CardFooter>
                            <Row>
                                <Col lg={3}></Col>
                                <Col lg={6}>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-info">Verander gegevens</button>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                </Col>
                            </Row>
                        </CardFooter>
                </form>
                :
                <div className="col-md-12">
                    <CardBody>
                                <legend className="mb-4">Bedrijfsgegevens</legend>
                                <fieldset>
                                    <div className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Vestigingsnaam</label>
                                        <Col md={6}>
                                            {this.state.vestigingen.officeName}
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Debiteurnummer</label>
                                        <Col md={6}>
                                            {this.state.vestigingen.debnr}
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Telefoonnummer</label>
                                        <Col md={6}>
                                            {this.state.vestigingen.telnr}
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label"> Bezoekadres</label>
                                            <Col md={6}>
                                                {this.state.vestigingen.bezoekAdres}
                                            </Col>
                                            <Col md={4}>
                                            </Col>
                                        </div>
                                        <div style={{ paddingTop: '10px' }} className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Bezoekpostcode en -plaats</label>
                                            <Col md={3}>
                                                {this.state.vestigingen.bezoekPc}
                                            </Col>
                                            <Col md={3}>
                                                {this.state.vestigingen.bezoekPlaats}
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label"> Postadres</label>
                                            <Col md={6}>
                                                {this.state.vestigingen.postAdres}
                                            </Col>
                                            <Col md={4}>
                                            </Col>
                                        </div>
                                        <div style={{ paddingTop: '10px' }} className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Postpostcode en -plaats</label>
                                            <Col md={3}>
                                                {this.state.vestigingen.postPc}
                                            </Col>
                                            <Col md={3}>
                                                {this.state.vestigingen.postPlaats}
                                            </Col>
                                        </div>
                                    </fieldset>
                            </CardBody>
                        </div>
            )
        } else {
            return(<div>loading...</div>)
        }
    }

}


const mapStateToProps = (state, props) => {
    const users = state.firestore.data.users;
    const user = users ? users[props.id] : null

    return {
        user,
        auth: state.firebase.auth,
        authError: state.auth.authError
        
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        verifyUser: (creds) => dispatch(verifyUser(creds)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'users'
    }])
)(UserGegevens)