import React from 'react';
import ResetPassword from './ResetPassword';
import qs from 'query-string'

const Action = (props) => {

  const mode = qs.parse(props.location.search, { ignoreQueryPrefix: true }).mode
  const actionCode = qs.parse(props.location.search, { ignoreQueryPrefix: true }).oobCode;

  switch (mode) {
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;
    default:
      // Error: invalid mode.
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}

export default Action;