import React from 'react';  
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import { compose } from 'redux'

const PrivateRoute = ({ profile, auth, component: Component, ...rest}) => ( 
  <Route {...rest} render={props => (
    auth.isLoaded   
    ? 
      !auth.uid 
      ? 
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}
        />
        :
        <Component {...props} />
    : null
  )} />
);
// Testing new Branch
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
    connect(mapStateToProps),
) (PrivateRoute) 

