import React from 'react';
import ContentWrapper from '../../../Layout/ContentWrapper';
import { connect } from 'react-redux'
import 'react-datetime/css/react-datetime.css';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Row, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import ListContactPersonen from './ListContactPersonen';
import firebase from '../../../../config/fbConfig';


class AdminGegevens extends React.Component {

    state = {
        officeName: ''

    }
    getDataInfo(id) {
      const db = firebase.firestore();
      db.collection('offices').doc(id).get()
          .then(doc => {

              this.setState({
                  officeName: doc.data().officeName,
              })
          });
  }

    componentDidMount() {
      this.getDataInfo(this.props.id)
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleBewerken = () => {
        this.setState({
            bewerken: !this.state.bewerken
        })
    }

    handleLoginClick = () => {
        this.setState({
            activeTab: 'Logins'
        })
    }

    render() {
        const { id } = this.props
        if (this.state) {
            return (
                <ContentWrapper>
                    <div className="content-heading">
                    <Row>
                      <Link to={'/klanten'}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link><p style={{paddingLeft: "20px"}}>{this.state.officeName}</p>
                    </Row>
                    </div>
                    <Card className="card card-transparent">
                        <Nav tabs>
                            <NavItem>
                                <NavLink tag={Link} to={`/klant/${id}/gegevens`}>
                                    <em className="fas fa-chevron-up fa-fw"></em>Gegevens
                                    </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to={`/klant/${id}/contactpersonen`} className="active">
                                    <em className="fas fa-chevron-down fa-fw"></em>Contactpersonen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to={`/klant/${id}/logins`}>
                                    <em className="fas fa-chevron-up fa-fw"></em>Logins
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to={`/klant/${id}/subscriptions`}>
                                    <em className="fas fa-chevron-up fa-fw"></em>Abonnementen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to={`/klant/${id}/documents`}>
                                    <em className="fas fa-chevron-up fa-fw"></em>Documenten
                                </NavLink>
                            </NavItem>
                            
                        </Nav>

                        <TabContent className="bg-white p-0">
                            <TabPane>
                                <ListContactPersonen id={id}/>
                            </TabPane>
                        </TabContent>
                    </Card>

                </ContentWrapper>
            );


        } else {
            return (<div>loading...</div>)
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      id: ownProps.match.params.id
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([])
)(AdminGegevens)