import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Row, Card, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../../Tables/Datatable';
import { firestoreConnect } from 'react-redux-firebase'
import { AccessToNewUser } from '../../../../store/actions/authActions'
import { dtOptions4 } from '../../../DMComponents/TableComponents'
import { EditButton, DeleteDocIcon, AddButton } from '../../../DMComponents/FormComponents';
import { deleteDoc } from '../../../../store/actions/loginInfoActions';


class ListContactPersonen extends Component {

    dtInstance = dtInstance => {
        const inputSearchClass = 'datatable_input_col_search';
        const columnInputs = $('tfoot .' + inputSearchClass);
        // On input keyup trigger filtering
        columnInputs
            .keyup(function () {
                dtInstance.fnFilter(this.value, columnInputs.index(this));
            });
    }

    swalCallback = (isConfirm, collection, doc) => {
      if (isConfirm) {
        this.props.deleteDoc(collection, doc)
      } 
    }
    swalAccessToNewUser = (isConfirm, doc) => {
      if (isConfirm) {
        this.props.AccessToNewUser(this.props.token, null ,doc)
      } 
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
				const { users, usersAccess} = this.props
        return (
            <div>
              <Card>
                <div className="col-md-12">
                  <CardBody>
                    {users &&
                      <Datatable options={dtOptions4}>
                        <table className="table w-100 table-striped">
                          <thead>
                            <tr>
                              <th className="sort-alpha">Naam</th>
                              <th className="sort-alpha">E-mail</th>
                              <th className="sort-alpha">DoorkiesNr</th>
                              <th className="sort-alpha">Mobiel</th>
                              <th className="sort-alpha">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map(user => {
                                return (
                                  <tr className="grade" key={user.id}>
                                    <td>{user.firstName}{user.midName !== ' ' && ` ${user.midName} `}{user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td><a href={`tel:${user.doorkiesNr}`}>{user.doorkiesNr}</a></td>
                                    <td><a href={`tel:${user.gsmnummer}`}>{user.gsmnummer}</a></td>
                                    <td><EditButton to={`/editcontactpersoon2/${user.id}`} label={'edit'}/></td>
                                  </tr>
                                )
                            })}
                          </tbody>
                        </table>
                      </Datatable>}
                    </CardBody>
                  </div>
                </Card>
                
                <Card>
                  <div className="col-md-12">
                    <CardBody>
                    <h4>Geregistreerde contactpersonen  (alleen de admin/owners)</h4>
                      {usersAccess ?
                      <Datatable options={dtOptions4}>
                        <table className="table w-100 table-striped">
                          <thead>
                            <tr>
                              <th>Naam</th>
                              <th>E-mail</th>
                              <th>DoorkiesNr</th>
                              <th>Mobiel</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                        <tbody>
                        { usersAccess && usersAccess.map(userAccess => {
                            return (
                            <tr className="grade" key={userAccess.id}>
                              <td>{userAccess.firstName}{userAccess.midName !== ' ' && ` ${userAccess.midName} `}{userAccess.lastName}</td>
                              <td>{userAccess.email}</td>
                              <td><a href={`tel:${userAccess.doorkiesNr}`}>{userAccess.doorkiesNr}</a></td>
                              <td><a href={`tel:${userAccess.gsmnummer}`}>{userAccess.gsmnummer}</a></td>
                              <td><Row><AddButton callback={(isConfirm)=> this.swalAccessToNewUser(isConfirm, userAccess.id)}/><DeleteDocIcon callback={(isConfirm) => this.swalCallback(isConfirm, 'usersAccess', userAccess.id)}/> <EditButton to={`/editaccesscontactpersoon/${userAccess.id}`}/></Row></td>
                            </tr>
                            )
                        })}
                        </tbody>
                        </table>
                        </Datatable>
                        : <div>Loading...</div> }
                    </CardBody>
                  </div>
                </Card>
              </div>
            );
    }

}

const mapStateToProps = (state) => {
  return {
    users: state.firestore.ordered.users,
    usersNew: state.firestore.ordered.usersNew,
    usersAccess: state.firestore.ordered.usersAccess,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    deleteDoc: (collection, doc) => dispatch(deleteDoc(collection, doc)),
    AccessToNewUser: (token, currentOffice, doc) => dispatch(AccessToNewUser(token, currentOffice, doc)),
  }
}


export default compose(connect(mapStateToProps, mapDispatchToProps),
firestoreConnect(props => [
  {
    collection: 'users',
  },
  {
    collection: 'usersAccess', where: ['owner', '==', true]
  }
])
  )(translate('translations')(ListContactPersonen))
  