const MenuTechnical = [
    {
        heading: 'Maxxus Medewerker',
    },
    {
      name: 'Dashboard',
      icon: 'icon-home',
      path: '/dashboard'
    },
    {
      name: 'Klanten',
      icon: 'icon-notebook',
      path: '/klanten'
    },
    {
      name: 'Contactpersonen',
      icon: 'icon-people',
      path: '/contactpersonen'
    },
    {
      name: 'Meldingen',
      icon: 'icon-note',
      path: '/meldingen'
    },
    {
      name: 'Csv Export',
      icon: 'icon-cloud-download',
      path: '/logintocsv'
    }
    

    

];

export default MenuTechnical;
