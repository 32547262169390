import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../Tables/Datatable';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { dtOptions1 } from '../../DMComponents/TableComponents'
import { EditButton } from '../../DMComponents/FormComponents';

class Logins extends Component {
    

    dtInstance = dtInstance => {
        const inputSearchClass = 'datatable_input_col_search';
        const columnInputs = $('tfoot .' + inputSearchClass);
        // On input keyup trigger filtering
        columnInputs
            .keyup(function () {
                dtInstance.fnFilter(this.value, columnInputs.index(this));
            });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const { meldingen } = this.props
        return (
                <Card> 
                    <CardHeader>
                        <Link to={'/newmelding'}><button className="btn btn-info">Nieuwe melding</button></Link>
                        </CardHeader>
                            <CardBody>
                            {meldingen ? 
                                <Datatable options={dtOptions1}>
                                    <table className="table bb">
                                        <thead>
                                            <tr>
                                              <th className="sort-alpha">login/product</th>
                                              <th className="sort-alpha">Pas aan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {meldingen && meldingen.map((melding) => {
                                            return (
                                                <tr className="grade" key={melding.id}>
                                                  <td>{melding.meldingName && melding.meldingName}</td>
                                                  <td><EditButton to={'/editmelding/' + melding.id} /></td>
                                                </tr>
                                              )
                                              })}
                                        </tbody>
                                    </table>
                                </Datatable>
                            : 
                            <div>Geen meldigen</div> }
                </CardBody>
								</Card>            
            );
    }

}

const mapStateToProps = (state) => {
    return {
      meldingen: state.firestore.ordered.meldingenMaxxus,
    }
  }


export default compose(connect(mapStateToProps),
firestoreConnect([
{
    collection: 'meldingenMaxxus'
}
])
  )(Logins)
  