import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardFooter, Input } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { newMeldingAction, testMelding } from '../../../store/actions/meldingActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';
import { FormItem, ConfirmButton, ConfirmTestMailButton } from '../../DMComponents/FormComponents';
import Select from 'react-select';

class NewLoginKlant extends Component {

  state = {
    melding: {
      meldingName: '',
      statusDone: false,
      meldingPortal: true,
      meldingEmail: true,
      meldingContent: 
`<!-- aanhef wordt automatisch toegevoegd aan e-mail -->
<p>Voorbeel bericht</p>
<!-- type het bericht in html boven deze lijn -->
<p>
Met vriendelijke groet,<br/>
Maxxus Communications<br/><br/>
T: 070 307 66 66 (Algemeen)<br/>
T: 070 307 66 88 (Service)<br/>
E: service@maxxus.nl<br/>
A: Winkelhaak 77, 2495 AX  Den Haag<br/>
</p>
<img src="https://p.maxxus.nl/img/logo2.png" style="padding-left: 10px; width:100px; height: 30px" />
`,
      loginId: [],
      emailTest: ""
    }
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      [form.name]: {
        ...this.state.melding,
        [input.name]: value
      }
    })
  }
    
  handleChangeSelectMulti = (selectedOptionMulti, type) => {
    this.setState({
      melding: {
        ...this.state.melding,
        [type]: selectedOptionMulti
      } 
    })
  }
    
  onSubmit = e => {
    const melding = {
      melding: {
      ...this.state.melding,
      loginId: this.state.melding.loginId
      }
    }
    this.props.newMeldingAction(melding)
  }

  ConfirmMelding = (isConfirm) => {
    if (isConfirm) {
      this.onSubmit()
    } 
  }

  ConfirmTest = (isConfirm) => {
    if (isConfirm) {
      this.SendTestMail()
    } 
  }

  SendTestMail = () => {
    const melding = {
      melding: {
      ...this.state.melding,
      loginId: this.state.melding.loginId
      }
    }
    this.props.testMelding(melding, this.props.profile)
  }
 
  render() {
      const { logins } = this.props
      return (
          <ContentWrapper>
              <div className="content-heading">
                <div>
                  <Link to={`/meldingen`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                </div>
              </div>
              <Row>
                  <div className="col-md-12">
                      <form onSubmit={this.ConfirmMelding()} action="" name="melding">
                          { /* START card */}
                          <Card className="card-default">
                              <CardBody>
                                  <legend className="mb-4">Melding toevoegen</legend>
                                  <fieldset>
                                      <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                          <label className="col-md-2 col-form-label">Login naam</label>
                                          <Col md={6}>
                                            <Select
                                                closeMenuOnSelect={false}
                                                name="loginId"
                                                isMulti
                                                value={this.state.melding.loginId}
                                                onChange={(value) => this.handleChangeSelectMulti(value, 'loginId')}
                                                options={logins}
                                            />
                                          </Col>
                                          <Col md={4}>
                                          </Col>
                                      </div>
                                  </fieldset>
                                  <fieldset className="last-child">
                                      <FormItem
                                        name= "meldingName"
                                        nameShow= "Naam melding"
                                        onChange={event => this.validateOnChange(event)}
                                        value={this.state.melding.meldingName}
                                        readOnly={this.state.formReadOnly}  
                                      />
                                      <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                      <label className="col-md-2 col-form-label">Melding</label>
                                      <Col md={8}>
                                      <textarea
                                        rows="16"
                                        className="form-control"
                                        name="meldingContent"
                                        onChange={event => this.validateOnChange(event)}
                                        value={this.state.melding.meldingContent}
                                        readOnly={this.state.formReadOnly}  
                                        />
                                      </Col>
                                      <Col md={2}>
                                      </Col>
                                      </div>
                                        
                                      <div className="form-group row align-items-center">
                                          <label className="col-md-2 col-form-label">Melding naar gebruikers</label>
                                          <Col md={ 4 }>
                                          <p>Melding Portal</p>
                                          <label className="switch">
                                              <input name='meldingPortal' type="checkbox" onChange={this.validateOnChange} defaultChecked={true} />
                                              <span></span>
                                          </label>
                                          </Col>
                                          <Col md={ 4 }>
                                          <p>E-mail</p>
                                          <label className="switch">
                                              <input name='meldingEmail' type="checkbox" onChange={this.validateOnChange} defaultChecked={true} />
                                              <span></span>
                                          </label>
                                          </Col>
                                      </div>
                                  </fieldset>
                                  <fieldset className="last-child">
                                  <div className="form-group row align-items-center">
                                    <label className="col-md-2 col-form-label">Email voor test</label>
                                    <Col  md={6}>
                                        <Input
                                            style={{marginBottom: "7px"}}
                                            type='text'
                                            name="emailTest"
                                            onChange={event => this.validateOnChange(event)}
                                            value={this.state.melding.emailTest}
                                            readOnly={this.state.formReadOnly}  
                                        />
                                    </Col>
                                    <Col md={4}>
                                    <ConfirmTestMailButton callback={this.ConfirmTest}label="Stuur test email" />
                                    </Col>
                                    </div>
                                  </fieldset>
                              </CardBody>
                              <CardFooter className="text-center">
                                <Row>
                                  <Col>

                                  </Col>
                                  <Col>
                                    <ConfirmButton callback={this.ConfirmMelding}label="Melding toevoegen / versturen" />
                                  </Col>
                                  <Col>
                                    <a href="https://html-online.com/editor/" rel="noopener noreferrer" target="_blank">HTML Editor</a>
                                  </Col>
                                </Row>
                              </CardFooter>
                          </Card>
                      </form>
                  </div>
              </Row>
          </ContentWrapper>
      )
  }
}
const mapStateToProps = (state) => {
    var logins =  state.firestore.ordered && 
                  state.firestore.ordered.logins && 
                  (state.firestore.ordered.logins.map(login => ({value: login.id, label: login.loginName.valueStatus}))).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    var profile = state.firebase.profile
    return {
        logins,
        profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newMeldingAction: (melding) => dispatch(newMeldingAction(melding)),
        testMelding: (melding, profile) => dispatch(testMelding(melding, profile))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'logins'
    }])
)(NewLoginKlant)
