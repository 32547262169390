import React, { Component } from 'react';
import ContentWrapper from '../../Layout/ContentWrapper';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DownloadButton } from '../../DMComponents/FormComponents';
import { getDownloadUrl } from '../../../store/actions/fileActions';
import Select from 'react-select'
import Uploader from './Uploader';

const fileArray = [{value: "cdr", label: "CDR"}, {value: "invoice", label: "Invoice"}, {value: "overig", label: "Overig"}, {value: "all", label: "Alles"}]

class ListDocuments extends Component {
  state={
    limit: 2,
    fileType: {value: "all", label: "Alles"}
  }

  handleChangeSelectMulti = (selectedOptionMulti, type) => {
    this.setState({
      [type]: selectedOptionMulti,
    })}

  render() {
    const { officesFiles } = this.props;
      return (
        <ContentWrapper>
          <Card>
            <CardBody>
              <Row>
                <Col md={4}>
                <Select
                name="fileType"  
                value={this.state.fileType}
                onChange={(value) => this.handleChangeSelectMulti(value, 'fileType')}
                options={fileArray}/>
                </Col>
                <Col md={5}>
                </Col>
                <Col md={3}>
                <Uploader show="Upload bestand" type={this.state.fileType.value} officeId={this.props.id} />
                </Col>
              </Row>
              
            </CardBody>
            <CardBody>
            <table className="table w-100">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Naam</th>
                  <th>Referentie</th>
                  <th>Document</th>
                  <th>Type</th>
                  <th>FileSize</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {officesFiles && officesFiles.map(file => {
                  if (this.state.fileType.value === "all") {
                    return (
                      <tr key={file.id}>
                        <td>{moment(file.date.seconds*1000).format('DD/MM/YYYY')}</td>
                        <td>{file.description}</td>
                        <td>{file.fileRef}</td>
                        <td>{file.type}</td>
                        <td>{file.contentType.split("/")[1] }</td>
                        <td>{(file.fileSize/1000000).toFixed(2) + ' mb'}</td>
                        <td><DownloadButton onClick={() => this.props.getDownloadUrl(file.filePath, file.description, file.contentType)}/></td>
                      </tr>
                    )
                  } else if (this.state.fileType.value === file.type) {
                    return (
                      <tr key={file.id}>
                        <td>{moment(file.date.seconds*1000).format('DD/MM/YYYY')}</td>
                        <td>{file.description}</td>
                        <td>{file.fileRef}</td>
                        <td>{file.type}</td>
                        <td>{file.contentType.split("/")[1] }</td>
                        <td>{(file.fileSize/1000000).toFixed(2) + ' mb'}</td>
                        <td><DownloadButton onClick={() => this.props.getDownloadUrl(file.filePath, file.description, file.contentType)}/></td>
                      </tr>
                    )
                  } else return null        
                })}
              </tbody>
            </table>
            </CardBody>
          </Card>
        </ContentWrapper>
      ) 
    }  
}

const mapStateToProps = (state) => {
  var officesFiles = state.firestore.ordered && state.firestore.ordered.officesFiles && state.firestore.ordered.officesFiles.slice().sort((a,b) => (a.date > b.date) ? -1 : ((b.date > a.date) ? 1 : 0))
  return {
    profile: state.firebase.profile,
    officesFiles: officesFiles,
    auth: state.firebase.auth
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getDownloadUrl: (a, b, c) => dispatch(getDownloadUrl(a, b, c)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [{
    collection: 'officesFiles', where: ['officeId', '==', props.id]
  }
  ])
  )(ListDocuments)
  