import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const apiPath = 'v1'

const productieDb = {
  apiKey: "AIzaSyD_BwEH1Nleu9ZZENhyOR980w6Vyvc7lms",
  authDomain: "maxxus-912a5.firebaseapp.com",
  databaseURL: "https://maxxus-912a5.firebaseio.com",
  projectId: "maxxus-912a5",
  storageBucket: "maxxus-912a5.appspot.com",
  messagingSenderId: "1003654953914"
}
/*
const testDb = {
  apiKey: "AIzaSyA_McWcH7_r260PDRa0vEwv9Gaj19zO_aM",
  authDomain: "maxxus-dev.firebaseapp.com",
  databaseURL: "https://maxxus-dev.firebaseio.com",
  projectId: "maxxus-dev",
  storageBucket: "maxxus-dev.appspot.com",
  messagingSenderId: "31300179752",
  appId: "1:31300179752:web:84854dfe5c120c3c"
};
*/
const config = productieDb

firebase.initializeApp(config);

export default firebase 
