import React, { Component } from 'react';
import { Row, Col, Input, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { getLoginInfo, updateLogin } from '../../../store/actions/loginInfoActions';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import firebase from '../../../config/fbConfig';
import NewLoginInfo from './NewLoginInfo';

class LoginInfoDetails extends Component {

    state = {
    }

    getInfo = (loginId) => {
        const db = firebase.firestore();
        db.collection('logins').doc(loginId).get()
            .then(doc => {
                var data = doc.data()
                this.setState(data)
            }).catch((err) => {
                console.log(err)
            });
    }

    validateOnChange = event => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        this.setState({
            [input.name]: {
                ...this.state[input.name],
                valueStatus: value,
            }
        })
    }

    onSubmit = e => {
        e.preventDefault()

        this.props.updateLogin(this.state, this.props.loginId)
    }

    componentDidMount = () => {
        const loginId = this.props.loginId;
        this.getInfo(loginId)
    }

    handleBewerken = () => {

    }

    render() {
        const { loginId } = this.props
        if (loginId === 'newlogin') {
            return (
                <NewLoginInfo />
            )
        } else if (loginId !== 'newlogin') {
            if (this.state.loginName) {
                return (
                    <ContentWrapper>
                        <div className="content-heading">
                          <div>
                            <Link to={`/logindetails`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                          </div>
                        </div>
                        <Row>
                        <div className="col-md-12">
                                <form onSubmit={this.onSubmit} action="" name="gegevens">
                                    { /* START card */}
                                    <Card className="card-default">
                                        <CardBody>
                                            <legend className="mb-4">Login gegevens <strong>{this.state.loginName.valueStatus}</strong> wijzigen</legend>
                                            <fieldset>
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Product/login</label>
                                                    <Col md={6}>
                                                        <strong>{this.state.loginName.valueStatus}</strong>
                                                    </Col>
                                                    <Col md={4}>
                                                    </Col>
                                                </div>
                                                <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Categorie</label>
                                                    <Col md={2}>
                                                        <strong>{this.state.category.valueStatus}</strong>
                                                    </Col>
                                                    <label className="col-md-2 col-form-label">End of Life</label>
                                                    <Col md={2}>
                                                        <label className="switch">
                                                            <input name='eol' checked={this.state.eol.valueStatus ? true : null} type='checkbox' onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Gebruikergegevens:</label>
                                                    <Col md={2}>
                                                        <p>Admin Login</p>
                                                        <label className="switch">
                                                            <input name='adminLogin' checked={this.state.adminLogin.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Admin Wachtwoord</p>
                                                        <label className="switch">
                                                            <input name='adminPassword' checked={this.state.adminPassword.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>User Login</p>
                                                        <label className="switch">
                                                            <input name='userLogin' checked={this.state.userLogin.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>User Wachtwoord</p>
                                                        <label className="switch">
                                                            <input name='userPassword' checked={this.state.userPassword.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Netwerk-velden:</label>
                                                    <Col md={2}>
                                                        <p>URL</p>
                                                        <label className="switch">
                                                            <input name='url' checked={this.state.url.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>WAN IP 1</p>
                                                        <label className="switch">
                                                            <input name='wanIp1' checked={this.state.wanIp1.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>WAN IP 2</p>
                                                        <label className="switch">
                                                            <input name='wanIp2' checked={this.state.wanIp2.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>LAN IP</p>
                                                        <label className="switch">
                                                            <input name='lanIp' checked={this.state.lanIp.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Overige-velden:</label>
                                                    <Col md={2}>
                                                        <p>Index</p>
                                                        <label className="switch">
                                                            <input name='index' checked={this.state.index.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                   <Col md={2}>
                                                        <p>Inbel-nummer</p>
                                                        <label className="switch">
                                                            <input name='inbelNummer' checked={this.state.inbelNummer.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Pincode</p>
                                                        <label className="switch">
                                                            <input name='pinCode' checked={this.state.pinCode.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Beschrijving</p>
                                                        <label className="switch">
                                                            <input name='eol' checked={this.state.description.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Extra-velden:</label>
                                                    <Col md={2}>
                                                        <p>Extra 1</p>
                                                        <label className="switch">
                                                            <input name='extra1' checked={this.state.extra1.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Extra 2</p>
                                                        <label className="switch">
                                                            <input name='extra2' checked={this.state.extra2.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Extra 3</p>
                                                        <label className="switch">
                                                            <input name='extra3' checked={this.state.extra3.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>Extra 4</p>
                                                        <label className="switch">
                                                            <input name='extra4' checked={this.state.extra4.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                <div className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Wifi-velden:</label>
                                                    <Col md={2}>
                                                        <p>SSID 1</p>
                                                        <label className="switch">
                                                            <input name='ssid1' checked={this.state.ssid1.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 1 key</p>
                                                        <label className="switch">
                                                            <input name='ssidKey1' checked={this.state.ssidKey1.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 1 info</p>
                                                        <label className="switch">
                                                            <input name='ssidInf1' checked={this.state.ssidInf1.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                                <div className="form-group row align-items-center">
                                                    <Col md={2}></Col>
                                                    <Col md={2}>
                                                        <p>SSID 2</p>
                                                        <label className="switch">
                                                            <input name='ssid2' checked={this.state.ssid2.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 2 key</p>
                                                        <label className="switch">
                                                            <input name='ssidKey2' checked={this.state.ssidKey2.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 2 info</p>
                                                        <label className="switch">
                                                            <input name='ssidInf2' checked={this.state.ssidInf2.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                                <div className="form-group row align-items-center">
                                                    <Col md={2}></Col>
                                                    <Col md={2}>
                                                        <p>SSID 3</p>
                                                        <label className="switch">
                                                            <input name='ssid3' checked={this.state.ssid3.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 3 key</p>
                                                        <label className="switch">
                                                            <input name='ssidKey3' checked={this.state.ssidKey3.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 3 info</p>
                                                        <label className="switch">
                                                            <input name='ssidInf3' checked={this.state.ssidInf3.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                                <div className="form-group row align-items-center">
                                                    <Col md={2}></Col>
                                                    <Col md={2}>
                                                        <p>SSID 4</p>
                                                        <label className="switch">
                                                            <input name='ssid4' checked={this.state.ssid4.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 4 key</p>
                                                        <label className="switch">
                                                            <input name='ssidKey4' checked={this.state.ssidKey4.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} />
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                    <Col md={2}>
                                                        <p>SSID 4 info</p>
                                                        <label className="switch">
                                                            <Input name='ssidInf4' checked={this.state.ssidInf4.valueStatus ? true : null}  type="checkbox" onChange={this.validateOnChange} readOnly/>
                                                            <span></span>
                                                        </label>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <button  type="submit" className="btn btn-info">Wijzigen login formulier opslaan</button>
                                        </CardFooter>
                                    </Card>
                                </form>
                            </div>
                        </Row>
                    </ContentWrapper>
                )
            } else {
              return (
                <div>loading...</div>
              )
            }
        }
    }
}
const mapStateToProps = (state, ownProps) => {
  const loginId = ownProps.match.params.loginId;
  const loginInfo = state.logins.loginInfo

  return {
      loginId,
      loginInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginInfo: (loginId) => getLoginInfo(loginId),
    updateLogin: (loginProp, loginId) => dispatch(updateLogin(loginProp, loginId))

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{
    collection: 'logins'
  }])
)(LoginInfoDetails)
