import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardHeader, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../Tables/Datatable';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { dtOptions1 } from '../../DMComponents/TableComponents'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { deleteDoc } from '../../../store/actions/loginInfoActions'
import firebase from '../../../config/fbConfig';


class Logins extends Component {
  state = {
    loginsOffices: []
  }

  componentDidMount(){
    this.getData('loginsOffices', this.props.id)
  }

   getData = (a, b) => {
     const db = firebase.firestore();
     db.collection(a).where('officeId', '==', b)
     .onSnapshot( (querySnapshot) => {
       var offices = [];
       querySnapshot.forEach(function(doc) {
        offices.push(doc)
       });
       this.setState({[a]: offices})
     }); 
   }

    dtInstance = dtInstance => {
      const inputSearchClass = 'datatable_input_col_search';
      const columnInputs = $('tfoot .' + inputSearchClass);
      // On input keyup trigger filtering
      columnInputs
          .keyup(function () {
              dtInstance.fnFilter(this.value, columnInputs.index(this));
          });
    }


    toggle = () => {
      this.setState({
          dropdownOpen: !this.state.dropdownOpen
      });
    }   

    swalCallback = (isConfirm, swal, collection, doc) => {
      if (isConfirm) {
        this.props.deleteDoc(collection, doc)
      } 
    }

    render() {
      const {id, logins } = this.props
      const { loginsOffices } = this.state
      return (
        <Card>
          <div className="col-md-12">
          <CardBody>
          <CardHeader>
          <Link to={'/newloginklant/' + this.props.id}><button className="btn btn-info">Nieuw login</button></Link>
          </CardHeader>
            {loginsOffices && loginsOffices.length > 0 && logins ? 
                <Datatable options={dtOptions1}>
                  <table className="table table-striped compact table-hover">
                    <thead>
                      <tr>
                        <th className="sort-alpha">Index</th>
                        <th className="sort-alpha">Login</th>
                        <th className="sort-alpha">Beschrijving</th>
                        <th className="sort-alpha">Url</th>
                        <th className="sort-alpha">Wan1</th>
                        <th className="sort-alpha">Wan2</th>
                        <th className="sort-alpha">Lan</th>
                        <th>Password</th>
                      </tr>
                  </thead>
                  <tbody>
                    {loginsOffices.map(loginDoc => {
                      const login = loginDoc.data()
                      return (
                        <tr className="grade" key={loginDoc.id}>
                          <td>{login.index}</td>
                          <td><Link to={'/klantproduct/' + id + '/' + loginDoc.id}>{logins[login.loginId] && logins[login.loginId].loginName.valueStatus}</Link> </td>
                          <td>{login.description}</td>
                          <td><a href={login.url} target="_blank" rel="noopener noreferrer">{login.url}</a></td>
                          <td><a href={login.wanIp1 && login.wanIp1.slice(0, 4) !== 'http' ? `http://${login.wanIp1}` : login.wanIp1} target="_blank" rel="noopener noreferrer">{login.wanIp1}</a></td>
                          <td><a href={login.wanIp2 && login.wanIp2.slice(0, 4) !== 'http' ? `http://${login.wanIp2}` : login.wanIp2} target="_blank" rel="noopener noreferrer">{login.wanIp2}</a></td>
                          <td><a href={login.lanIp && login.lanIp.slice(0, 4) !== 'http' ? `http://${login.lanIp}` : login.lanIp} target="_blank" rel="noopener noreferrer">{login.lanIp}</a></td>
                          <td>
                          <CopyToClipboard text={login.adminPassword}>
                              <i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} className="icon-orange fas fa-copy"></i>
                          </CopyToClipboard>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </Datatable>
                : 
                <div>Klant heeft geen login's</div> 
              }
          </CardBody>
          </div>
        </Card>
      );
    }
}

const mapStateToProps = (state) => {
    return {
      logins: state.firestore.data.logins,
    }
  }
const mapDispatchToProps = (dispatch)=> {
    return {
      deleteDoc: (collection, doc) => dispatch(deleteDoc(collection, doc))
    }
  }

export default compose(connect(mapStateToProps, mapDispatchToProps),
firestoreConnect((props) => [
{
  collection: 'logins', orderBy: ['loginName'] 
}
])
  )(translate('translations')(Logins))
  
