import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { signIn } from '../../store/actions/authActions'
import FormValidator from '../Forms/FormValidator.js';
import { compose } from 'recompose';

const year = new Date().getFullYear()

class Login extends Component {

    state = {
            formLogin: {
                email: '',
                password: '',
                tfa: ''
            },
            errors: {
                
            }
        }
     /**
      * Validate input using onChange event
      * @param  {String} formName The name of the form in the state object
      * @return {Function} a function used for the event
      */
    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    }
    
    onSubmit = e => {
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (hasError) console.log('Form has errors. Check!')
        else {
            this.props.signIn(this.state)
        } 
        e.preventDefault()
    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    }

    render() {
        const { auth } = this.props;
        
        if (this.props.type === 'SUCCESS' && auth.uid) return <Redirect to='/' />
        else {
            return (
                <div className="block-center mt-4 wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded" src="img/logo.png" alt="Logo"/>
                            </a>
                        </div>
                        <div className="card-body">
                            <p className="text-center py-2">LOG IN ALS MEDEWERKER</p>
                            <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input type="email"
                                            id="email"
                                            name="email"
                                            className="border-right-0"
                                            placeholder="E-mailadres"
                                            invalid={this.hasError('formLogin','email','required')||this.hasError('formLogin','email','email')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required", "email"]'
                                            value={this.state.formLogin.email}/>
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-envelope"></em>
                                            </span>
                                        </div>
                                        { this.hasError('formLogin','email','required') && <span className="invalid-feedback">Veld is verplicht</span> }
                                        { this.hasError('formLogin','email','email') && <span className="invalid-feedback">Veld bevat geen geldig e-mailadres</span> }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input type="password"
                                            id="password"
                                            name="password"
                                            className="border-right-0"
                                            placeholder="Wachtwoord"
                                            invalid={this.hasError('formLogin','password','required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formLogin.password}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-lock"></em>
                                            </span>
                                        </div>
                                        <span className="invalid-feedback">Veld is verplicht</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group with-focus">
                                        <Input type="text"
                                            id="tfa"
                                            name="tfa"
                                            className="border-right-0"
                                            placeholder="2FA Token"
                                            invalid={this.hasError('formLogin','tfa','required')}
                                            onChange={this.validateOnChange}
                                            //data-validate='["required"]'
                                            value={this.state.formLogin.tfa}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-lock"></em>
                                            </span>
                                        </div>
                                        <span className="invalid-feedback">Veld is verplicht</span>
                                    </div>
                                </div>
                                <div className="clearfix">
                                    <div className="checkbox c-checkbox float-left mt-0">
                                        <label>
                                            <input type="checkbox" value="" name="remember"/>
                                            <span className="fa fa-check"></span>Onthoud mij</label>
                                    </div>
                                    <div className="float-right">
                                        <Link to="recover" className="text-muted">Wachtwoord vergeten?</Link>
                                    </div>
                                </div>
                                <button className="btn btn-block btn-primary mt-3" type="submit">Login</button>
                            </form>
                        </div>
                    </div>
                    <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>{year}</span>
                    <span className="mx-2">-</span>
                    <span>MaXXus Communications - 1.3.0 </span>
                    <br/>
                </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    
    return {
      auth: state.firebase.auth,
      user: state.firebase.profile,
      type: state.auth.type
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      signIn: (creds) => dispatch(signIn(creds)),
    }
  }

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Login)

