import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { newSubscriptionOffice } from '../../../store/actions/SubscriptionActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';



class NewSubscriptionKlant extends Component {

    state = {
        

    }

    validateOnChange =( event, name ) => {
        console.log(this.state)
        const { subscriptions } = this.props
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        if (name === "index") {
        const description = subscriptions[value].description;
          this.setState({
            description: description,
            [input.name]: value
        })
        } else {
          this.setState({
            [input.name]: value,
        })
        }

        
    }

    onSubmit = e => {
        e.preventDefault()
        //const form = e.target;
        // const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))
        // const { errors } = FormValidator.bulkValidate(inputs)
        this.props.newSubscriptionOffice(this.state, this.props.officeId)
        
    }

    render() {
        const { subscriptions, officeId } = this.props

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Login Toevoegen
                        <small>Login toevoegen aan klant</small><br/>
                        <Link to={`/klant/${officeId}`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                    </div>
                </div>
                <Row>
                    <div className="col-md-12">
                        <form onSubmit={this.onSubmit} action="" name="gegevens">
                            { /* START card */}
                            <Card className="card-default">
                                <CardBody>
                                    <legend className="mb-4">Abonnement gegevens</legend>
                                    <fieldset>
                                        <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Login naam</label> 
                                            <Col md={6}>
                                                <select name="subscriptionId" value={this.state.value} onChange={ event => {this.validateOnChange(event, 'index')}}>
                                                <option>Abonnementsnaam</option>
                                                    {subscriptions && Object.keys(subscriptions).map(index => {
                                                        return (
                                                            <option key={index} value={index}>{subscriptions[index].description}</option>
                                                        )
                                                    })}
                                                </select>
                                            </Col>
                                            <Col md={4}>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    {this.state.subscriptionId && 
                                    <fieldset className="last-child">
                                    <div style={{ paddingBottom: '10px' }} className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Aantal</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.amount}
                                                name="amount" />
                                        </Col>
																				<Col md={4}></Col>
                                        <label className="col-md-2 col-form-label">Beschrijving abonnement</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.description}
                                                name="description" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">start- en einddatum</label>
                                        <Col md={3}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.startDate}
                                                name="startDate" />
                                        </Col>
																				<Col md={3}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.endDate}
                                                name="endDate" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Hoofd nummer</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.mainNumber}
                                                name="mainNumber" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Nummerblok</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.numberSeries}
                                                name="numberSeries" />
                                        </Col>
																				<Col md={4}></Col>
																				<label className="col-md-2 col-form-label">Verkoopprijs</label>
                                        <Col md={6}>
                                            <input
                                                rows="4"
                                                className="form-control"
                                                onChange={this.validateOnChange}
                                                value={this.state.sellingPrice}
                                                name="sellingPrice" />
                                        </Col>
																				<Col md={4}></Col>
                                    </div>                                       
                                    </fieldset>
                                }
                                </CardBody>
                                <CardFooter className="text-center">
                                    <button type="submit" className="btn btn-info">Abonnement toevoegen</button>
                                </CardFooter>
                                
                            </Card>
                        </form>
                    </div>
                </Row>
            </ContentWrapper>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        subscriptions: state.firestore.data.subscriptions,
        officeId: ownProps.match.params.officeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newSubscriptionOffice: (subscriptionProp, officeId) => dispatch(newSubscriptionOffice(subscriptionProp, officeId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'subscriptions'
    }])
)(NewSubscriptionKlant)
