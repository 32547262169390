import firebase, { apiPath } from '../../config/fbConfig';
import axios from 'axios';

let onSuccess = (message) => {
  return {
      type: 'SUCCESS',
      payload: message,
    }
}

let resetState = () => {
  return {
    type: "RESET_STATUS"
  }
}

function onError(error) {
  return {
    type: 'ERROR',
    error 
  }
}


export const signIn = (credentials) => {
  return async (dispatch) => {
    try {
      const signIn = await firebase.auth().signInWithEmailAndPassword(credentials.formLogin.email, credentials.formLogin.password)
      const customToken = await axios.get(`https://api.maxxus.nl/${apiPath}/auth/admintoken/${signIn.user.h.b}/tfa/${credentials.formLogin.tfa}`)
      await firebase.auth().signOut()
      if (customToken.status === 200) {
        if (customToken.data.status === 'ERROR') {
          dispatch(onError({message: customToken.data.msg}))
          return await setTimeout(() => { dispatch(resetState()) }, 8000) 
        } 
        else {
          await firebase.auth().signInWithCustomToken(customToken.data.adminToken)
          dispatch(onSuccess(customToken.data.msg))
          return await setTimeout(() => { dispatch(resetState()) }, 6000)
        }
      }
      else {
        dispatch(onError({message: 'Error in verificatie, probeer het later opnieuw'}))
        return await setTimeout(() => { dispatch(resetState()) }, 8000)
      } 
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } , 10000)  
    }
  }
}

export const signOut = () => {
  return async (dispatch) => {
    try {
      await firebase.auth().signOut()

      dispatch(onSuccess('Succesvol uitgelogd'))
      dispatch({type: 'SIGN_OUT'})
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}

export const signUp = ( cred ) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();

    try {
      const userAuth = await firebase.auth().createUserWithEmailAndPassword(cred.formRegister.email, cred.formRegister.password)
      await firestore.collection('users').doc(userAuth.user.uid).set({
        firstName: (cred.formRegister.firstName),
        lastName: cred.formRegister.lastName,
        midName: cred.formRegister.midName,
        gsmnummer: cred.formRegister.gsmnummer,
        doorkiesNr: cred.formRegister.doorkiesNr,
        email: cred.formRegister.email,
        OldNUID: cred.formRegister.nuid
      })

      const newUser = await firestore.collection("usersNew").doc(cred.formRegister.nuid).get()
      await firestore.collection('users').doc(userAuth.user.uid).update({
        gender: newUser.data().gender,
        currentOffice: newUser.data().currentOffice,
        offices: newUser.data().offices,
        officeArray: newUser.data().officeArray,
      })

      await firestore.collection("newUsers").doc(cred.formRegister.nuid).update({status: 'complete'})

      dispatch(onSuccess('Succevol aangemeld'))

      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}

export const makeNewUser = (user, admin, token) => {
  return async (dispatch) => {
    try {
      var signInToken = await axios.post(`https://api.maxxus.nl/${apiPath}/signup/newuser/token/${token}/currentOffice/${admin.currentOffice}`, {
          user: {
            email: user.gegevens.email,
            firstName: user.gegevens.firstName,
            lastName: user.gegevens.lastName,
            midName: user.gegevens.midName,
            gsmnummer: user.gegevens.gsmnummer,
            doorkiesNr: user.gegevens.doorkiesNr,
            currentOffice: admin.currentOffice,
            gender: user.gegevens.gender,
            officeArray: [admin.currentOffice],
            owner: user.gegevens.owner,
            initials: ''
          },
          rights: {
            emailMeldingen: user.rights.emailMeldingen,
            administration: user.rights.administration,
            meldingen: user.rights.meldingen,
            owner: user.rights.owner,
          }
      })
      if (signInToken.data.status === 'SUCCESS') {
        dispatch(onSuccess(signInToken.data.msg))
      } else dispatch(onError({message: signInToken.data.msg}))

      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}
    
export const newKlantAction = (state) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();

    try {
      const createNewCompany = await firestore.collection('companies').add(state.company)

      const createNewOffice = await firestore.collection('offices').add({
        ...state.office,
        companyId: createNewCompany.id
      })

      await firestore.collection('usersNew').add({
        ...state.newUser,
        currentOffice: createNewOffice.id,
        offices: {
          [createNewOffice.id]: {
            owner: true,
            technical: true,
            commercial: true,
            administration: true
          }
        }
      })

      dispatch(onSuccess('Nieuwe gebruiker toegevoegd'))

      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}


export const editUser = (state, id, office) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const token = getState().firebase.auth.stsTokenManager.accessToken
    try {
      await axios.post(`https://api.maxxus.nl/${apiPath}/auth/edit/email`, {email: state.editUser.email, uid: id, token})
      await firestore.collection('users').doc(id).update(state.editUser)
      if (state.rights) {
        await firestore.collection(`offices/${office}/users`).doc(id).update(state.rights)
      }
      dispatch(onSuccess('Gebruiker succesvol aangepast'))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}

export const editUserAccess = (state, id) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();
    try {
      await firestore.collection('usersAccess').doc(id).update(state.editUser)
      dispatch(onSuccess('Geregistreerde gebruiker succesvol aangepast'))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}


export const verifyUser = (user) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();

    try {
      await firestore.collection('users').doc(user.uid).update(user.gegevens)
      dispatch(onSuccess('Gegevens aangepast'))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)  
    }
  }
};

export const resetPassword = (email) => {
  return async (dispatch) => {

    try {
      await firebase.auth().sendPasswordResetEmail(email.email)
      dispatch(onSuccess('Wachtwoord reset-link verstuurd'))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)  
    }
  }
};

export const currentCompany = (a) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const uid = getState().firebase.auth.uid;

    try {
      await firestore.collection('users').doc(uid).update({currentOffice: a})
      dispatch(onSuccess('Bedrijf succesvol gewijzigd'))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,3000)  
    }
  }
};

export const AccessToNewUser = (a, currentOffice, doc) => {
  return async (dispatch, getState) => {
    const token = getState().firebase.auth.stsTokenManager.accessToken;
    try {
      const signInToken = await axios.get(`https://api.maxxus.nl/${apiPath}/signup/token/${token}/currentOffice/${currentOffice}/doc/${doc}`)
 
      if (signInToken.data.status === 'SUCCESS') {
        dispatch(onSuccess(signInToken.data.msg))
        return await setTimeout(() => { dispatch(resetState()) }, 3000)
      } else {
        dispatch(onError({message: signInToken.data.msg}))
        return await setTimeout(() => { dispatch(resetState()) }, 6000)
      } 
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) }, 3000)  
    }
  }
};

export const newContactPersoon = (user, office, token) => {
  return async (dispatch) => {
    try {
      var signInToken = await axios.post(`https://api.maxxus.nl/${apiPath}/signup/newuser/token/${token}/currentOffice/${office}`, {
          user: {
            email: user.newUser.email,
            firstName: user.newUser.firstName,
            lastName: user.newUser.lastName,
            midName: user.newUser.midName,
            gsmnummer: user.newUser.gsmnummer,
            doorkiesNr: user.newUser.doorkiesNr,
            currentOffice: office,
            gender: user.newUser.gender,
            officeArray: [office],
            owner: user.newUser.owner,
          },
          rights: {
            emailMeldingen: true,
            administration: true,
            meldingen: true,
            owner: true,
          }
      })
      if (signInToken.data.status === 'SUCCESS') {
        dispatch(onSuccess(signInToken.data.msg))
      } else dispatch(onError({message: signInToken.data.msg}))

      return await setTimeout(() => { dispatch(resetState()) } ,3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } ,10000)  
    }
  }
}

export const tfaSetup = (tfaInfo, uid) => {
  return async (dispatch) => {
    const firestore = firebase.firestore();
    if (tfaInfo.valid) {
      try {
        await firestore.collection('usersMaxxusTfa').doc(uid).set({tfaInfo})
        await firestore.collection('usersMaxxus').doc(uid).update({tfaEnabled: true})
        dispatch(onSuccess('TFA succesvol geactiveerd, log opnieuw in'))
        await setTimeout(() => { dispatch(resetState()) }, 5000)
        return await setTimeout(() => { dispatch(signOut())}, 5000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) }, 9000)  
      }
    } else {
      dispatch(onError({message: 'Token onjuist, probeer het nog een keer'}))
      return await setTimeout(() => { dispatch(resetState()) }, 9000)  
    } 
  }
}


export const SignInAsIf = (uid) => {
  return async (dispatch, getState) => {
    const token = getState().firebase.auth.stsTokenManager.accessToken
    try {
      var signInToken = await axios.post(`https://api.maxxus.nl/${apiPath}/auth/signinasif/`, {
        token,
        uid
      })
      return dispatch({
        type: 'SIGN_IN_AS_IF',
        payload: signInToken.data.token,
      })
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) }, 10000)  
    }
  }
}
