import firebase from '../../config/fbConfig';

let onSuccess = (message) => {
  return {
      type: 'SUCCESS',
      payload: message 
    }
  }
  
let resetState = () => {
  return {
    type: "RESET_STATUS"
  }
}

function onError(error) {
  return {
    type: 'ERROR',
    error 
  }
}

export const getSubscriptionInfo = (subscriptionId) => {
  return async (dispatch) => {
      const db = firebase.firestore();
      
      try {
        await db.collection('subscriptions').add(subscriptionId)

        dispatch(onSuccess('Abonnementen succesvol opgehaald'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }      
  }
}

export const newSubscription = (subscriptionProp) => {
  return async (dispatch) => {
      const db = firebase.firestore();
      
      try {
        await db.collection('subscriptions').add(subscriptionProp)
        dispatch(onSuccess('Abonnement succesvol toegevoegd aan lijst'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }
  }
}

export const updateSubscription = (subscriptionId, officeId, subscriptionProp) => {
  return async (dispatch) => {
      const db = firebase.firestore();
      
      try {
        await db.collection(`offices/${officeId}/subscriptions`).doc(subscriptionId).update(subscriptionProp)
        dispatch(onSuccess('Abonnement succesvol aangepast'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } , 10000)
      }
  }
}

export const newSubscriptionOffice = (subscriptionProp, officeId) => {
  return async (dispatch, getState) => {
      const db = firebase.firestore();
      const uid = getState().firebase.auth.uid;
      const data = {
        ...subscriptionProp,
        updateBy: uid,
        lastUpdate: new Date()
      }
      
      try {
        await db.collection(`offices/${officeId}/subscriptions`).add(data)
        dispatch(onSuccess('Abonnement succesvol toegevoegd aan klant'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }
  }
}