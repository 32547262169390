import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardHeader, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../Tables/Datatable';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { EditButton } from '../../DMComponents/FormComponents';
import { dtOptions1 } from '../../DMComponents/TableComponents'

class LoginInfo extends Component {

    state = { }

    // Access to internal datatable instance for customizations
    dtInstance = dtInstance => {
        const inputSearchClass = 'datatable_input_col_search';
        const columnInputs = $('tfoot .' + inputSearchClass);
        // On input keyup trigger filtering
        columnInputs
            .keyup(function () {
                dtInstance.fnFilter(this.value, columnInputs.index(this));
            });
    }


    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {

        const { logins } = this.props

        return (
          <Card>
              <CardHeader>
                <Link to={'/logindetail/newlogin'}><button className="btn btn-info">Product login toevoegen</button></Link>
              </CardHeader>
              <CardBody>
                {logins ?
                  <Datatable options={dtOptions1}>
                    <table className="table table-striped my-4 w-100">
                      <thead>
                        <tr>
                          <th className="sort-alpha">Login</th>
                          <th>Categorie</th>
                          <th>Login id</th>
                          <th>Info</th>
                        </tr>
                      </thead>
                        <tbody>
                          {logins && Object.keys(logins).map(login => {
                            return (
                              <tr className="grade" key={login}>
                                <td>{logins[login].loginName.valueStatus}</td>
                                <td>{logins[login].category.valueStatus}</td>
                                <td>{login}</td>
                                <td><EditButton to={'/logindetail/' + [login]} label={'edit'}/></td>
                              </tr>
                            )
                          })}
                        </tbody>
                    </table>
                  </Datatable>
                : <div>Geen logins beschikbaar</div>}
              </CardBody>
          </Card>
        );

    }

}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    logins: state.firestore.data.logins
  }
}


export default compose(connect(mapStateToProps),
  firestoreConnect([{
      collection: 'logins'
  },
])
)(translate('translations')(LoginInfo))
