import React, { Component } from 'react'
import { Row, Col, Input, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { createLogin } from '../../../store/actions/loginInfoActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';


class NewLoginInfo extends Component {

    state = {
 
        loginName: { name: 'Product/login', valueStatus: '', type: 'text', index: 100},
        category: { name: 'Categorie', valueStatus: 'PBX', type: 'text', index: 101},
        adminLogin: { name: 'Admin Login', valueStatus: true, type: 'text', index: 102},
        adminPassword: { name: 'Admin Password', valueStatus: true, type: 'password', index: 103},
        userLogin: { name: 'User Login', valueStatus: false, type: 'text', index: 104},
        userPassword: { name: 'User Password', valueStatus: false, type: 'password', index: 105},
        url: { name: 'URL', valueStatus: true, type: 'url', index: 106},
        wanIp1: { name: 'WAN IP 1', valueStatus: true, type: 'url', index: 107},
        wanIp2: { name: 'WAN IP 2', valueStatus: true, type: 'url', index: 108},
        lanIp: { name: 'LAN IP', valueStatus: true, type: 'url', index: 109},
        index: { name: 'index', valueStatus: true, type: 'text', index: 110},
        inbelNummer: { name: 'Inbelnummer', valueStatus: false, type: 'text', index: 111},
        pinCode: {name: 'Pincode', valueStatus: false, type: 'number', index: 112},
        description: { name: 'Beschrijving', valueStatus: true, type: 'text', index: 112 }, 
        eol: { name: 'End of Life', valueStatus: false, type: 'boolean', index: 113},
        extra1: { name: 'Extra 1', valueStatus: true, type: 'textBox', index: 126},
        extra2: { name: 'Extra 2', valueStatus: false, type: 'textBox', index: 127},
        extra3: { name: 'Extra 3', valueStatus: false, type: 'textBox', index: 128},
        extra4: { name: 'Extra 4', valueStatus: false, type: 'textBox', index: 129},
        ssid1: { name: 'SSID 1', valueStatus: false, type: 'text', index: 114},
        ssidKey1: { name: 'SSID 1 key', valueStatus: false, type: 'text', index: 115},
        ssidInf1: { name: 'SSID 1 info', valueStatus: false, type: 'text', index: 116},
        ssid2: { name: 'SSID 2', valueStatus: false, type: 'text', index: 117},
        ssidKey2: { name: 'SSID 2 key', valueStatus: false, type: 'text', index: 118},
        ssidInf2: { name: 'SSID 2 info', valueStatus: false, type: 'text', index: 119},
        ssid3: { name: 'SSID 3', valueStatus: false, type: 'text', index: 120},
        ssidKey3: { name: 'SSID 3 key', valueStatus: false, type: 'text', index: 121},
        ssidInf3: { name: 'SSID 3 info', valueStatus: false, type: 'text', index: 122},
        ssid4: { name: 'SSID 4', valueStatus: false, type: 'text', index: 123},
        ssidKey4: { name: 'SSID 4 key', valueStatus: false, type: 'text', index: 124},
        ssidInf4: { name: 'SSID 4 info', valueStatus: false, type: 'text', index: 125}
    }
    // Bovenstaande state moet overeenkomen met variabelen in de form (zie render) 

    validateOnChange = event => {
      const input = event.target;
      const value = input.type === 'checkbox' ? input.checked : input.value;

      this.setState({
        [input.name]: {
          ...this.state[input.name],
          valueStatus: value,
        }
      })
    }

    onSubmit = e => {
      e.preventDefault()
      this.props.createLogin(this.state)
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Product Login Toevoegen
                        <small>Product toevoegen aan loginlijst</small>
                    </div>
                </div>
                <Row>
                    <div className="col-md-12">
                        <form onSubmit={this.onSubmit} action="" name="gegevens">
                            { /* START card */}
                            <Card className="card-default">
                                <CardBody>
                                    <fieldset>
                                        <Link to={`/logindetails`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Product/Login</label>
                                            <Col md={6}>
                                                <Input type="text"
                                                    name="loginName"
                                                    onChange={this.validateOnChange}
                                                    value={this.state.loginName.valueStatus}
                                                />
                                            </Col>
                                            <Col md={4}>
                                            </Col>
                                        </div>
                                        <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Categorie</label>
                                            <Col md={2}>
                                                <select name="category" value={this.state.value} onChange={this.validateOnChange}>
                                                    <option value="PBX">PBX</option>
                                                    <option value="Router">Router</option>
                                                    <option value="RouterAP">Router + AP</option>
                                                    <option value="AP">Access Point</option>
                                                    <option value="Switch">Switch</option>
                                                    <option value="Server">Server / PC</option>
                                                    <option value="VoIP Gateway">VoIP Gateway</option>
                                                    <option value="SIP Endpoint">SIP Endpoint</option>
                                                    <option value="Internet">Internet Verbinding</option>
                                                    <option value="Trunk">Trunk</option>
                                                    <option value="VPN">VPN</option>
                                                    <option value="Other">Overig</option>
                                                </select>
                                            </Col>
                                            <label className="col-md-2 col-form-label">End of Life</label>
                                            <Col md={2}>
                                                <label className="switch">
                                                    <input name='eol' type='checkbox' onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>                                 
                                        </div>
                                    </fieldset>
                                    <fieldset className="last-child">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Gebruikergegevens:</label>
                                            <Col md={2}>
                                                <p>Admin Login</p>
                                                <label className="switch">
                                                    <input name='adminLogin' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Admin Wachtwoord</p>
                                                <label className="switch">
                                                    <input name='adminPassword' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>User Login</p>
                                                <label className="switch">
                                                    <input name='userLogin' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>User Wachtwoord</p>
                                                <label className="switch">
                                                    <input name='userPassword' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <fieldset className="last-child">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Netwerk-velden:</label>
                                            <Col md={2}>
                                                <p>URL</p>
                                                <label className="switch">
                                                    <input name='url' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>WAN IP 1</p>
                                                <label className="switch">
                                                    <input name='wanIp1' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>WAN IP 2</p>
                                                <label className="switch">
                                                    <input name='wanIp2' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>LAN IP</p>
                                                <label className="switch">
                                                    <input name='lanIp' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <fieldset className="last-child">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Overige-velden:</label>
                                            <Col md={2}>
                                                <p>Beschrijving</p>
                                                <label className="switch">
                                                    <input name='pinCode' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Index</p>
                                                <label className="switch">
                                                    <input name='index' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Inbelnummer</p>
                                                <label className="switch">
                                                    <input name='inbelNummer' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Pincode</p>
                                                <label className="switch">
                                                    <input name='pinCode' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <fieldset className="last-child">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Extra-velden:</label>
                                            <Col md={2}>
                                                <p>Extra 1</p>
                                                <label className="switch">
                                                    <input name='extra1' type="checkbox" defaultChecked onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Extra 2</p>
                                                <label className="switch">
                                                    <input name='extra2' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Extra 3</p>
                                                <label className="switch">
                                                    <input name='extra3' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>Extra 4</p>
                                                <label className="switch">
                                                    <input name='extra4' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <fieldset className="last-child">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-2 col-form-label">Wifi-velden:</label>
                                            <Col md={2}>
                                                <p>SSID 1</p>
                                                <label className="switch">
                                                    <input name='ssid1' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 1 key</p>
                                                <label className="switch">
                                                    <input name='ssidKey1' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 1 info</p>
                                                <label className="switch">
                                                    <input name='ssidInf1' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <Col md={2}></Col>
                                            <Col md={2}>
                                                <p>SSID 2</p>
                                                <label className="switch">
                                                    <input name='ssid2' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 2 key</p>
                                                <label className="switch">
                                                    <input name='ssidKey2' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 2 info</p>
                                                <label className="switch">
                                                    <input name='ssidInf2' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <Col md={2}></Col>
                                            <Col md={2}>
                                                <p>SSID 3</p>
                                                <label className="switch">
                                                    <input name='ssid3' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 3 key</p>
                                                <label className="switch">
                                                    <input name='ssidKey3' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 3 info</p>
                                                <label className="switch">
                                                    <input name='ssidInf3' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <Col md={2}></Col>
                                            <Col md={2}>
                                                <p>SSID 4</p>
                                                <label className="switch">
                                                    <input name='ssid4' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 4 key</p>
                                                <label className="switch">
                                                    <input name='ssidKey4' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                            <Col md={2}>
                                                <p>SSID 4 info</p>
                                                <label className="switch">
                                                    <input name='ssidInf4' type="checkbox" onChange={this.validateOnChange} />
                                                    <span></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </fieldset>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <button type="submit" className="btn btn-info">Login Toevoegen</button>
                                </CardFooter>
                            </Card>
                        </form>
                    </div>
                </Row>
            </ContentWrapper>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createLogin: (loginProp) => dispatch(createLogin(loginProp)),
    }
}

export default compose(
  connect(null, mapDispatchToProps),
  firestoreConnect([{
    collection: 'logins'
  }])
)(NewLoginInfo)
