import React, { Component } from 'react';

import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

class Spinner extends Component {

    render() {
        return (

            <div className="card-body d-flex justify-content-center align-middle">
            <div className="ball-scale-ripple-multiple">
                                    <div></div>
                                    <div></div>
                                    <div></div>
            </div>
            </div>
            );
    }

}

export default Spinner;