
import React, { Component } from 'react'
import { Card, CardBody, Col } from 'reactstrap';
import { tfaSetup } from '../../store/actions/authActions';
import authenticator from 'otplib/authenticator';
import QRCode from 'qrcode';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import crypto from 'crypto';

class tfaAuth extends Component {

  setup = (e) => {
      authenticator.options = { crypto }
      const secret = authenticator.generateSecret();
      const otpauth = authenticator.keyuri(`${this.props.userEmail}`, 'Maxxus', secret);
      QRCode.toDataURL(otpauth, (err, data_url) => {
          if (err) {
              console.log('error with QR')
          }
          const tfaInfo = {
              tempSecret: secret,
              dataURL: data_url,
          };

          return this.setState(tfaInfo);
      })
      e.preventDefault()
  }

  handleChange = (e) => {
      this.setState({
          ...this.state,
          [e.target.name]: e.target.value 
      })
  }

  tfaCheck = (e) => {
      e.preventDefault()
      
      const verified = authenticator.verify({
          secret: this.state.tempSecret, //secret of the logged in user
          token: this.state.tfaToken
      });
      
      if (verified) {
          const tfaInfo = {
              secret: this.state.tempSecret,
              valid: true
          }
          const { uid } = this.props
          this.props.tfaSetup(tfaInfo, uid)
      } else {
          const tfaInfo = {
            secret: this.state.tempSecret,
            valid: false
          }
          const { uid } = this.props
          this.props.tfaSetup(tfaInfo, uid)
      }

  }
  
  render() {
    return (
        
          <Card className="card-default">
              <CardBody>
              <legend className="mb-4">2FA Google Authenticator</legend>
                  <ol>
                      <fieldset>
                          <Col md={6} >
                              <h4><li>Download de app</li></h4>
                              IOS gebruikers kunnen de app downloaden in de app store, de app kan gevonden worden wanneer er wordt gezocht op "Authenticator".
                              Andriod gebruikers kunnen inloggen in Google Play, of zoeken op "Google Authenticator" in de mobiele browser.
                          </Col>
                          <Col md={4}></Col>
                      </fieldset>
                      <fieldset>
                          <Col md= {6}>
                              <h4><li>Voeg Maxxus toe in Google Autenticator</li></h4>
                              Geneer de key, open de app en voeg Maxxus toe door de QR-code te scannen. Mocht dit niet lukken dan kan de code ook handmatig worden ingevoerd.
                          </Col>
                          <Col md={4}></Col>
                      </fieldset>
                      <fieldset>
                      <Col md= {6}>
                          <h4><li>Voer de 6-cijferige code in</li></h4>
                          Bevestig dat Maxxus succesvol toegevoegd is in Google Authenticator door de 6-cijferige code in te vullen. 
                      </Col>
                      <Col md={4}></Col>
                      </fieldset>
                          <br></br>
                          {this.state && this.state.dataURL ?
                              <div>
                              <Col md={4}>
                                  <img src={this.state.dataURL} alt="..." className="img-thumbnail" />
                                  <br />
                                  <p> <strong> {'Key: ' + this.state.tempSecret } </strong> </p>
                                  <form className="mb-3" name="formLogin" onSubmit={this.tfaCheck}>
                                      <div className="form-group">
                                          <div className="input-group with-focus">
                                              
                                                  <Input type="text"
                                                      id="tfa"
                                                      name="tfaToken"
                                                      className="border-right-0"
                                                      placeholder="Token"
                                                      value={this.state.tfaToken}
                                                      onChange={this.handleChange}
                                                  />

                                              <span className="invalid-feedback">Vul de token in</span>
                                          </div>
                                      </div>
                                      <button className="btn btn-block btn-primary mt-3" type="submit">Bevestig</button>
                                  </form>
                              </Col>
                              <Col md={6}></Col>
                              </div>
                              : <button className="btn btn-info" onClick={this.setup}>Genereer key</button>}
                      </ol>

              </CardBody>
          </Card>
    )
  }
}     

const mapStateToProps = (state) => {
    return {
        userEmail: state.firebase.auth.email,
        uid: state.firebase.auth.uid
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tfaSetup: (tfaInfo, uid) => dispatch(tfaSetup(tfaInfo, uid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(tfaAuth)