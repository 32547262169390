import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, Row, CardHeader, CardBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux'
import { compose } from 'redux'
import Datatable from '../../../Tables/Datatable';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { AccessToNewUser, SignInAsIf } from '../../../../store/actions/authActions'
import { dtOptions1 } from '../../../DMComponents/TableComponents'
import { EditButton, DeleteButton, AddButton, DeleteButtonSwal } from '../../../DMComponents/FormComponents';
import firebase from '../../../../config/fbConfig';
import { deleteDoc } from '../../../../store/actions/loginInfoActions';


class ListContactPersonen extends Component {
    state = {}
    dtInstance = dtInstance => {
        const inputSearchClass = 'datatable_input_col_search';
        const columnInputs = $('tfoot .' + inputSearchClass);
        // On input keyup trigger filtering
        columnInputs
            .keyup(function () {
                dtInstance.fnFilter(this.value, columnInputs.index(this));
            });
    }

    componentDidMount(){
     this.getUsers('users', this.props.id)
     this.getUsers('usersAccess', this.props.id)
    }

    swalAccessToNewUser = (isConfirm, doc) => {
      if (isConfirm) {
        this.props.AccessToNewUser(this.props.token, null ,doc)
      } 
    }

    DeleteUserConfirm = (isConfirm, doc) => {
      if (isConfirm) {
        this.props.deleteDoc('users', doc)
      } 
    }

    getUsers = (a, b) => {
      const db = firebase.firestore();
      db.collection(a).where('officeArray', 'array-contains', b)
      .onSnapshot( (querySnapshot) => {
        var users = [];
        querySnapshot.forEach(function(doc) {
            users.push(doc)
        });
        this.setState({[a]: users, [`${a}Collected`]: true})
      }); 
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const { id, signInAsIfToken } = this.props
        const { users, usersAccess, usersCollected, usersAccessCollected } = this.state
        if (signInAsIfToken) {window.location.href = `https://portal.maxxus.nl/signinasif/${signInAsIfToken}`}
        return (
            <div>
              <Card>
                <div className="col-md-12">
                  <CardBody>
                    <CardHeader>
                      <Link to={'/newcontactpersoon/'+ id}><button className="btn btn-info">Nieuw contactpersoon</button></Link>
                    </CardHeader>
                    {usersCollected ? 
                      <Datatable options={dtOptions1}>
                        <table className="table table-striped my-4 w-100">
                          <thead>
                            <tr>
                              <th className="sort-alpha">Naam</th>
                              <th className="sort-alpha">E-mail</th>
                              <th className="sort-alpha">DoorkiesNr</th>
                              <th className="sort-alpha">Mobiel</th>
                              <th className="sort-alpha">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map(userDoc => {
                              var user = userDoc.data()
                                return (
                                  <tr className="grade" key={userDoc.id}>
                                    <td>{user.firstName}{user.midName !== ' ' && ` ${user.midName} `}{user.lastName}</td>
                                    <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                                    <td><a href={`tel:${user.doorkiesNr}`}>{user.doorkiesNr}</a></td>
                                    <td><a href={`tel:${user.gsmnummer}`}>{user.gsmnummer}</a></td>
                                    <td><Row><i style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} onClick={() => this.props.signInAsIf(userDoc.id)} className="icon-orange fas fa-sign-in-alt"></i><EditButton to={`/editcontactpersoon/${id}/${userDoc.id}`} label={'edit'}/><DeleteButtonSwal callback={(isConfirm) => this.DeleteUserConfirm(isConfirm, userDoc.id)}/> </Row></td>
                                  </tr>
                                )
                            })}
                          </tbody>
                        </table>
                      </Datatable>
                    : 
                      <div>Klant heeft geen contactpersonen</div> }
                    </CardBody>
                  </div>
                </Card>
                <Card>
                  <div className="col-md-12">
                    <CardBody>
                      <h4>Geregistreerde contactpersonen</h4>
                        <table className="table w-100 table-striped">
                          <thead>
                            <tr>
                              <th>Naam</th>
                              <th>E-mail</th>
                              <th>DoorkiesNr</th>
                              <th>Mobiel</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                        <tbody>
                        { usersAccessCollected && usersAccess.map(newUserAccessDoc => {
                            var newUserAccess = newUserAccessDoc.data()
                            return (
                            <tr className="grade" key={newUserAccessDoc.id}>
                              <td>{newUserAccess.firstName}{newUserAccess.midName !== ' ' && ` ${newUserAccess.midName} `}{newUserAccess.lastName}</td>
                              <td><a href={`mailto:${newUserAccess.email}`}>{newUserAccess.email}</a></td>
                              <td><a href={`tel:${newUserAccess.doorkiesNr}`}>{newUserAccess.doorkiesNr}</a></td>
                              <td><a href={`tel:${newUserAccess.gsmnummer}`}>{newUserAccess.gsmnummer}</a></td>
                              <td><Row><EditButton to={`/editaccesscontactpersoon2/${id}/${newUserAccessDoc.id}`} label={'edit'}/> <AddButton callback={(isConfirm)=> this.swalAccessToNewUser(isConfirm, newUserAccessDoc.id)}/><DeleteButton onClick={()=> this.props.deleteDoc('usersAccess', newUserAccessDoc.id)}/></Row></td>
                            </tr>
                            )
                        })}
                        </tbody>
                        </table>
                    </CardBody>
                  </div>
                </Card>
              </div>
            );
    } 
}
const mapStateToProps = (state, ownProps) => {
  return {
    signInAsIfToken: state.auth.signInAsIfToken,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    deleteDoc: (collection, doc) => dispatch(deleteDoc(collection, doc)),
    AccessToNewUser: (token, currentOffice, doc) => dispatch(AccessToNewUser(token, currentOffice, doc)),
    signInAsIf: (uid) => dispatch(SignInAsIf(uid))
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),
firestoreConnect(props => [
])
  )(translate('translations')(ListContactPersonen))
  