const initState = {
  signInAsIfToken: false
}

const authReducer = (state = initState, action) => {
  switch(action.type) {
    case 'SUCCESS':
      return {
        ...state,
        successMessage: action.payload,
        type: action.type
      }
    case 'ERROR':
      return {
        ...state,
        errorMessage: action.error.message
      }
    case 'RESET_STATUS': 
      return {
        ...state,
        type: null,
        successMessage: null,
        errorMessage: null,
      }
    case 'CLAIMS':
      return {
        ...state,
        admin: action.payload
      }
    case 'SIGN_IN_AS_IF':
      return {
        ...state,
        signInAsIfToken: action.payload
      }
    default:
      return state
  }
};

export default authReducer;