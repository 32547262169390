import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import Spinner from '../Elements/Spinner'

class SidebarUserBlock extends Component {

    state = {
        showUserBlock: true
    }

    componentWillReceiveProps(newProps) {
        if (newProps.showUserBlock !== this.props.showUserBlock) {
            this.setState({ showUserBlock: newProps.showUserBlock })
        }
    }

render() {
    const { user} = this.props;
    // Usse t function instead of Trans component
    // const { t } = this.props;
    if (user) {
        return (
            <Collapse id="user-block" isOpen={ this.state.showUserBlock }>
                <div>
                    <div className="item user-block">
                       {/* User picture */}
                       <div className="user-block-picture">
                          <div className="user-block-status">

                          </div>
                       </div>
                       {/* Name and Job */}
                       <div className="user-block-info">
                          <span className="user-block-name">{user.firstName&&user.firstName}</span>
                          <span className="user-block-role">Medewerker</span><br />
                          <button className="btn btn-info" onClick={this.props.signOut}>Logout</button>
                       </div>
                    </div>
                </div>
            </Collapse>
        )
      } else {
          return(<Spinner />)
      }
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
      user: state.firebase.profile,
      auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    signOut: () => dispatch(signOut())
    }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SidebarUserBlock)
