import React from 'react';
import { translate } from 'react-i18next';
import ContentWrapper from '../../Layout/ContentWrapper';
import { connect } from 'react-redux'
import { compose } from 'redux'
import TfaSetup from '../../Auth/TfaSetUp';


const DashboardV1 = ({ profile }) => (
  <ContentWrapper>
    {profile.isLoaded &&
      <div>
        <h3>Welkom {profile.firstName}</h3>
        {profile.tfaEnabled ?
          <h3>Bedankt voor het activeren van de 2FA!</h3> :
          <div>
            <h3>Activeer nu je Two Factor Authentication code</h3>
            <br></br>
            <TfaSetup />
          </div>}
      </div>
    }
  </ContentWrapper>
)

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps),
)(translate('translations')(DashboardV1))