import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/app';
import { unregister } from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { getFirestore, createFirestoreInstance, reduxFirestore } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import fbConfig from './config/fbConfig';
import './i18n';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composeEnhancers = compose;

const rrfConfig = { userProfile: 'usersMaxxus', useFirestoreForProfile: true }

const middleware = [
  thunk.withExtraArgument({ getFirestore }),
  // This is where you add other middleware like redux-observable
];

const store = createStore(rootReducer,
  composeEnhancers(
        reduxFirestore(fbConfig),
        applyMiddleware(...middleware)
    )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

  ReactDOM.render(
    <Provider store={store}>
       <ReactReduxFirebaseProvider {...rrfProps}>
            <App />
        </ReactReduxFirebaseProvider>
    </Provider>, 
    document.getElementById('app'));
    unregister()
