import React from 'react';
import { Alert } from 'reactstrap'
import { connect } from 'react-redux'

const SuccessMessage = ({message}) => (
    <Alert
    style={{position: "fixed", bottom: "20px", right: "20px", zIndex: "1000", }}
    color="success"
    >
    {message}
    </Alert>   
)

const ErrorMessage = ({message}) => (
    <Alert
    style={{position: "fixed", bottom: "20px", right: "20px", zIndex: "1000"}}
    color="warning"
    >
    {message}
    </Alert>
)

const BasePage = props => (

        <div className="wrapper">
            { props.children }
            {props.successMessage && <SuccessMessage message={props.successMessage}/>}
            {props.errorMessage && <ErrorMessage message={props.errorMessage}/>}
        </div>
    
)

const mapStateToProps = (state) => {
    return {
        successMessage: state.auth.successMessage,
        errorMessage: state.auth.errorMessage,
    }
}

export default connect(mapStateToProps, null)(BasePage)
