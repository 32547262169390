import React, { Component } from "react";
import { translate } from "react-i18next";
import { Card, CardHeader, CardBody } from "reactstrap";
import $ from "jquery";
import { connect } from "react-redux";
import { compose } from "redux";
import Datatable from "../../Tables/Datatable";
import { firestoreConnect } from "react-redux-firebase";
import { dtOptions4 } from "../../DMComponents/TableComponents";

class Klanten extends Component {
  state = {};

  // Access to internal datatable instance for customizations
  dtInstance = dtInstance => {
    const inputSearchClass = "datatable_input_col_search";
    const columnInputs = $("tfoot ." + inputSearchClass);
    // On input keyup trigger filtering
    columnInputs.keyup(function() {
      dtInstance.fnFilter(this.value, columnInputs.index(this));
    });
  };

  changeLanguage = lng => {
    this.props.i18n.changeLanguage(lng);
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { vestigingen } = this.props;
    // Usse t function instead of Trans component
    // const { t } = this.props;
    //return (<p>{ this.state.usersInfo || 'Loading' }</p>);

    return (
      <Card>
        <CardHeader>
          {/*<Link to="/newklant"><button className="btn btn-info">Nieuwe klant</button></Link>*/}
        </CardHeader>
        <CardBody>
          {vestigingen ? (
            <Datatable options={dtOptions4}>
              <table className="table table-striped my-4 w-100">
                <thead>
                  <tr>
                    <th className="sort-alpha">Vestiging</th>
                    <th>Debiteurnummer</th>
                    <th>Telefoonnummer</th>
                    <th className="sort-alpha" data-priority="1">
                      Adres
                    </th>
                    <th className="sort-alpha" data-priority="1">
                      Postcode
                    </th>
                    <th className="sort-alpha" data-priority="1">
                      Plaats
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vestigingen &&
                    vestigingen.map(vestiging => {
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          className="grade"
                          key={[vestiging.id]}
                        >
                          <td>
                            <a href={`/klant/${[vestiging.id]}/gegevens`} rel="noopener noreferrer" target="_blank">
                              {vestiging.officeName}
                            </a>
                          </td>
                          <td>{vestiging.debnr}</td>
                          <td>
                            <a
                              href={`tel:${vestiging.telnr && vestiging.telnr}`}
                            >
                              {vestiging.telnr && vestiging.telnr}
                            </a>
                          </td>
                          <td>{vestiging.bezoekAdres}</td>
                          <td>
                            <a
                              href={`https://www.google.nl/maps/dir/Winkelhaak+77,+2495+AX+Den+Haag/${vestiging.bezoekAdres}+${vestiging.bezoekPc}+${vestiging.bezoekPlaats}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vestiging.bezoekPc && vestiging.bezoekPc}
                            </a>
                          </td>
                          <td>{vestiging.bezoekPlaats}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Datatable>
          ) : (
            <div>loading...</div>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    vestigingen: state.firestore.ordered.offices,
    companies: state.firestore.data.companies
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "offices"
    }
  ])
)(translate("translations")(Klanten));
