import React, { Component } from 'react'
import { Row, Col, Input, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { updateLoginKlant } from '../../../store/actions/loginInfoActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import firebase from '../../../config/fbConfig';
import { withRouter } from 'react-router';
import moment from 'moment'
import FormValidator from '../../Forms/FormValidator.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DeleteDocButton } from '../../DMComponents/FormComponents';
import { deleteDoc } from '../../../store/actions/loginInfoActions'
import { Link } from 'react-router-dom';
import * as generator from 'generate-password';

class Logingegevens extends Component {

    state = {
        bewerken: false,
        passwordShow: false
    }

    getData() {
        const db = firebase.firestore();
        const { klantLoginId } = this.props
        db.collection(`loginsOffices`).doc(klantLoginId).get()
            .then((doc) => {
                var login = doc.data()
                this.setState({ login: login })
            })
            .catch(error => {
                console.log("Error getting documents: ", error);
            });
    }

    componentDidMount() {
        this.getData()
    }

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;
        console.log('clg', this.state)
        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
            },
            errors: {
                ...this.state[form.name].errors,
                [input.name]: result
            }
        });

    }

    hasError = (inputName, method) => {
        return this.state &&
            this.state.errors &&
            this.state.errors[inputName] &&
            this.state.errors[inputName][method]
    }

    onSubmit = e => {
        e.preventDefault()
        //const form = e.target
        //const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        //const { errors, hasError } = FormValidator.validate(inputs)

        this.setState({
            ...this.state,
            bewerken: false,

        })
        this.props.updateLoginKlant(this.state, this.props.officeId, this.props.klantLoginId)
    }

    handleBewerken = () => {
        this.setState({
            bewerken: !this.state.bewerken
        })
    }

    handleTogglePassword = () => {
        this.setState({
            passwordShow: !this.state.passwordShow
        })
    }

    handleGeneratePassword = () => {
        const password = generator.generate({
            length: 20,
            numbers: true,
            symbols: true,
            exclude: ",;#<>"
        });
        this.setState({
            ...this.state,
            login: {
                ...this.state.login,
                adminPassword: password,
            }
        })
    }

    swalCallback = (isConfirm, swal, collection, doc) => {
        if (isConfirm) {
            this.props.deleteDoc(collection, doc)
        }
    }

    render() {
        const { logins } = this.props
        const { login, bewerken } = this.state
        //Deze volgorde wordt aangehouden in het formulier
        var values = ['loginName', 'description', 'category', 'adminLogin', 'adminPassword', 'userLogin', 'userPassword', 'url', 'wanIp1', 'wanIp2', 'lanIp', 'index', 'inbelNummer', 'pinCode', 'ssid1', 'ssidKey1', 'ssidInf1', 'ssid2', 'ssidKey2', 'ssidInf2', 'ssid3', 'ssidKey3', 'ssidInf3', 'ssid4', 'ssidKey4', 'ssidInf4', 'extra1', 'extra2', 'extra3', 'extra4']

        if (logins && this.state.login && this.state.login.loginId) {
            return (
                <ContentWrapper>
                    <div className="content-heading">
                        <div>
                            Login Veranderen
                            <small>Logingegevens veranderen</small><br />
                            <button className="btn btn-info" onClick={() => this.props.history.goBack()}><i className="fas fa-arrow-left"></i>  Terug</button>   <button onClick={this.handleBewerken} className={bewerken === true ? "btn btn-danger" : "btn btn-info"}>Bewerken</button>   <Link to={'/klant/' + login.officeId + '/gegevens'}><button className={"btn btn-info"}>Naar klant</button></Link>
                        </div>
                    </div>
                    <Row>
                        <div className="col-md-12">
                            {bewerken === false ?
                                <Card className="card-default">
                                    <CardBody>
                                        <legend className="mb-4">Login gegevens</legend>
                                        {/** Start first part */}
                                        <fieldset>
                                            <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                                <label className="col-md-2 col-form-label">Naam</label>
                                                <Col md={6}>
                                                    <Input plaintext>
                                                        <strong>{logins[login.loginId].loginName.valueStatus}</strong>
                                                    </Input>
                                                </Col>
                                                <Col md={4}>
                                                </Col>
                                            </div>

                                            <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                                <label className="col-md-2 col-form-label">Categorie</label>
                                                <Col md={6}>
                                                    <Input plaintext>
                                                        <strong>{logins[login.loginId].category.valueStatus}</strong>
                                                    </Input>
                                                </Col>
                                                <Col md={4}>
                                                </Col>
                                            </div>
                                        </fieldset>
                                        {/** End first part */}
                                        {/** Start first part */}
                                        <fieldset className="last-child">
                                            {logins && logins[login.loginId] && values.map(index => {
                                                if (index !== 'category' && index !== 'loginName' && logins[login.loginId][index].valueStatus === true) {
                                                    if (logins[login.loginId][index].type === 'text' || logins[login.loginId][index].type === 'textBox') {
                                                        return (
                                                            <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                <Col md={6}>
                                                                    <Input plaintext>
                                                                        {login[index]}
                                                                    </Input>
                                                                </Col>
                                                            </div>
                                                        )
                                                    }

                                                    if (logins[login.loginId][index].type === 'password') {
                                                        return (
                                                            <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                <Col md={4}>
                                                                    {this.state.passwordShow === true ? login[index] : '******'}
                                                                </Col>
                                                                <Col md={4}>
                                                                    <CopyToClipboard text={login[index]}>
                                                                        <button className="btn btn-info">Copy</button>
                                                                    </CopyToClipboard>
                                                                    <button className="btn btn-info" onClick={() => { this.handleTogglePassword() }}>Show</button>
                                                                </Col>
                                                            </div>
                                                        )
                                                    }

                                                    if (logins[login.loginId][index].type === 'url') {
                                                        return (
                                                            <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                <Col md={6}>
                                                                    <a href={`${login[index]}`} rel="noopener noreferrer" target="_blank">{login[index]}</a>
                                                                </Col>
                                                            </div>
                                                        )
                                                    }
                                                } else return null
                                                return null
                                            })}
                                        </fieldset>
                                        {/** End second part */}
                                    </CardBody>
                                    <CardFooter className="text-left">
                                        {login.lastUpdate ? "Laatste update op " + moment(login.lastUpdate.seconds * 1000).format('DD-MM-YYYY  HH:mm') : 'onbekend'} {login.updateBy ? " door " + login.updateBy : "Laatste update is niet bekend"}
                                    </CardFooter>
                                </Card>
                                :
                                <form onSubmit={this.onSubmit} action="" name="login">
                                    <Card className="card-default">
                                        <CardBody>
                                            <legend className="mb-4">Login bewerken</legend>
                                            <fieldset>
                                                <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                                    <label className="col-md-2 col-form-label">Naam</label>
                                                    <Col md={6}>
                                                        <Input
                                                            className="border-right-0"
                                                            value={logins[login.loginId].loginName.valueStatus}
                                                            readOnly />
                                                    </Col>
                                                    <Col md={4}>
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset className="last-child">
                                                {logins && logins[login.loginId] && values.map(index => {
                                                    if (index !== 'loginName' && logins[login.loginId][index].valueStatus === true) {
                                                        if (logins[login.loginId][index].type === 'text' || logins[login.loginId][index].type === 'url' || logins[login.loginId][index].type === 'password') {
                                                            if (index === 'description') {
                                                                return (
                                                                    <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                        <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                        <Col md={6}>
                                                                            <Input type="text"
                                                                                className="border-right-0"
                                                                                invalid={this.hasError('description', 'len')}
                                                                                onChange={this.validateOnChange}
                                                                                name="description"
                                                                                data-validate='["len"]'
                                                                                value={login[index]}
                                                                                data-param="[0, 20]"
                                                                            />
                                                                            {this.hasError('login', 'description', 'len') && <span className="invalid-feedback">Beschrijving mag maximaal 20 karacters bevatten</span>}
                                                                        </Col>
                                                                    </div>
                                                                )
                                                            } else if (index === "adminPassword") {
                                                                return (
                                                                    <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                        <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                        <Col md={6}>
                                                                            <Input
                                                                                className="border-right-0"
                                                                                onChange={this.validateOnChange}
                                                                                value={login[index]}
                                                                                name={index} />
                                                                        </Col>
                                                                        <Col md={2}>
                                                                        <button type="button" className="btn btn-info" onClick={this.handleGeneratePassword}>Generate</button>
                                                                        </Col>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                        <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                        <Col md={6}>
                                                                            <Input
                                                                                className="border-right-0"
                                                                                onChange={this.validateOnChange}
                                                                                value={login[index]}
                                                                                name={index} />
                                                                        </Col>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        if (logins[login.loginId][index].type === 'textBox') {
                                                            return (
                                                                <div style={{ paddingBottom: '10px' }} key={index} className="form-group row align-items-center">
                                                                    <label className="col-md-2 col-form-label">{logins[login.loginId][index].name}</label>
                                                                    <Col md={6}>
                                                                        <textarea
                                                                            rows="4"
                                                                            className="form-control note-editor note-editor"
                                                                            onChange={this.validateOnChange}
                                                                            value={login[index]}
                                                                            name={index} />
                                                                    </Col>
                                                                </div>
                                                            )
                                                        }
                                                    } else return null
                                                    return null
                                                })}
                                            </fieldset>

                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <Row>
                                                <Col>
                                                </Col>
                                                <Col>
                                                    <button type="submit" className="btn btn-info">Wijzigingen opslaan</button>
                                                </Col>
                                                <Col>
                                                    <DeleteDocButton callback={(isConfirm, swal) => this.swalCallback(isConfirm, swal, 'loginsOffices', this.props.klantLoginId)} />
                                                </Col>
                                            </Row>
                                        </CardFooter>

                                    </Card>
                                </form>
                            }
                        </div>
                    </Row>
                </ContentWrapper>
            )
        } else {
            return null
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    const users = state.firestore.data.users;
    const logins = state.firestore.data.logins;
    const klantLoginId = ownProps.match.params.loginId;
    const officeId = ownProps.match.params.officeId;


    return {
        users,
        logins,
        auth: state.firebase.auth,
        authError: state.auth.authError,
        klantLoginId,
        officeId,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLoginKlant: (loginProp, officeId, loginId) => dispatch(updateLoginKlant(loginProp, officeId, loginId)),
        deleteDoc: (collection, doc) => dispatch(deleteDoc(collection, doc)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'logins' },
    ])
)(Logingegevens)
