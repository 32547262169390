import React, { Component } from 'react';
import firebase from '../../config/fbConfig.js'
import { Input } from 'reactstrap';
import FormValidator from '../Forms/FormValidator.js';

const year = new Date().getFullYear()

export default class ResetPassword extends Component {
  state = {
    email: null,
    error: '',
    password: '',
    success: false,
    validCode: null,
    verifiedCode: false,
  }

  componentDidMount() {
    // Verify the password reset code is valid.
    firebase.auth()
      .verifyPasswordResetCode(this.props.actionCode)
      .then(email => {
        this.setState({ email, validCode: true, verifiedCode: true });
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    const { actionCode } = this.props;
    const newPassword = this.state.password;
    const inputs = [...event.target.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))
    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
        ...this.state,
        errors
    });

    if (!hasError) {
    // Save the new password.
    firebase.auth()
      .confirmPasswordReset(actionCode, newPassword)
      .then(() => {
        // Password reset has been confirmed and new password updated.
        this.setState({ success: true });
      }, error => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        this.setState({ error: error.message });
      });
    } else {this.setState({ error: 'Uw wachtwoord voldoet niet aan de voorwaarde' })}
    
  }
  validateOnChange = event => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
            [input.name]: value,
            errors: {
                ...this.state.errors,
                [input.name]: result
            }
        
    });
  }

  hasError = (inputName, method) => {
    return this.state &&
        this.state.errors &&
        this.state.errors[inputName] &&
        this.state.errors[inputName][method]
  }

  render() {
    const {
      email,
      error,
      password,
      password2,
      success,
      validCode,
      verifiedCode,
    } = this.state;
    let component;
    if (!verifiedCode) {
      component = <p>Loading...</p>;
    } else if (success) {
      component = (
        <div className="block-center mt-4 wd-xl">
        {/* START card */}
        <div className="card card-flat">
            <div className="card-body">
                <p className="text-center py-2">WACHTWOORD SUCCESVOL AANGEPAST</p>
                    <div className="form-group">
                        <label className="text-muted" htmlFor="signupInputEmail1">Uw e-mailadres</label>
                        <div className="input-group with-focus">
                            <Input type="email"
                                name="email"
                                className="border-right-0"
                                placeholder="E-mailadres"
                                readOnly                                
                                data-validate='["required", "email"]'
                                value={email} />
                            <div className="input-group-append">
                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                    <em className="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                    <a href="logins"><button className="text-center btn btn-info">Login</button></a>
                    </div>
            </div>
        </div>
        <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>{year}</span>
                    <span className="mx-2">-</span>
                    <span>MaXXus Communications</span>
                    <br/>
        </div>
    </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div className="block-center mt-4 wd-xl">
        {/* START card */}
        <div className="card card-flat">
            <div className="card-body">
                <p className="text-center py-2">VERANDER WACHTWOORD</p>
                <form className="mb-3" name="formRegister" onSubmit={this.handleResetPassword}>
                    <div className="form-group">
                        <label className="text-muted" htmlFor="signupInputEmail1">Uw e-mailadres</label>
                        <div className="input-group with-focus">
                            <Input type="email"
                                name="email"
                                className="border-right-0"
                                placeholder="E-mailadres"
                                readOnly                                
                                data-validate='["required", "email"]'
                                value={email} />
                            <div className="input-group-append">
                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                    <em className="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="text-muted" htmlFor="signupInputPassword1">Wachtwoord</label>
                        <div className="input-group with-focus">
                            <Input type="password"
                                id="id-password"
                                name="password"
                                className="border-right-0"
                                placeholder="Wachtwoord"
                                invalid={this.hasError('password', 'required') || this.hasError('password', 'len') || this.hasError('password', 'passwordCapital') || this.hasError('password', 'passwordSmallLetter') || this.hasError('password', 'passwordNumber') || this.hasError('password', 'passwordSymbol') }
                                onChange={this.validateOnChange}
                                data-validate='["required", "len", "passwordCapital", "passwordSmallLetter", "passwordNumber", "passwordSymbol"]'
                                value={password}
                                data-param="[6, 50]"
                            />
                            <div className="input-group-append">
                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                    <em className="fa fa-lock"></em>
                                </span>
                            </div>
                            {this.hasError('password', 'required') && <span className="invalid-feedback">Veld is verplicht</span>}
                            {this.hasError('password', 'len') && <span className="invalid-feedback">Wachtwoord moet miniaal 6 tekens bevatten</span>}
                            {this.hasError('password', 'passwordCapital') && <span className="invalid-feedback">Wachtwoord bevat geen hoofdletter</span>}
                            {this.hasError('password', 'passwordSmallLetter') && <span className="invalid-feedback">Wachtwoord bevat geen kleine letter</span>}
                            {this.hasError('password', 'passwordNumber') && <span className="invalid-feedback">Wachtwoord bevat geen nummer</span>}
                            {this.hasError('password', 'passwordSymbol') && <span className="invalid-feedback">Wachtwoord bevat geen symbool</span>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="text-muted" htmlFor="signupInputRePassword1">Bevestig wachtwoord</label>
                        <div className="input-group with-focus">
                            <Input type="password"
                                name="password2"
                                className="border-right-0"
                                placeholder="Bevestig wachtwoord"
                                invalid={this.hasError('password2', 'equalto')}
                                onChange={this.validateOnChange}
                                data-validate='["equalto"]'
                                value={password2}
                                data-param="id-password"
                            />
                            <div className="input-group-append">
                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                    <em className="fa fa-lock"></em>
                                </span>
                            </div>
                            <span className="invalid-feedback">Wachtwoorden komen niet overeen</span>
                        </div>
                    </div>
                    <button className="btn btn-block btn-primary mt-3" type="submit">Wachtwoord veranderen</button>
                </form>
                {error ? <p class="text-danger">{error}</p> : ''}  
                </div>          
        </div>
        <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>{year}</span>
                    <span className="mx-2">-</span>
                    <span>MaXXus Communications</span>
                    <br/>
        </div>
    </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="block-center mt-4 wd-xl">
        {/* START card */}
        <div className="card card-flat">
            <div className="card-body text-center">
                <h4 className="py-2">WACHTWOORD VERANDEREN</h4>
                
                    <p className="py-2">De url is verlopen, probeer het opnieuw</p>
                    <a href="recover"><button className="text-center btn btn-info">Terug naar wachtwoord herstel pagina</button></a>
                
                
            </div>
        </div>
        <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>{year}</span>
                    <span className="mx-2">-</span>
                    <span>MaXXus Communications</span>
                    <br/>
        </div>
    </div>
      );
    }

    return component;
  }
}