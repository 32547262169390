import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../../Layout/ContentWrapper';
import { LoginToCsvOffices, LoginToCsvUsers } from '../../../../store/actions/LoginToCsvActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Select from 'react-select';

class NewLoginKlant extends Component {

    state = {
        loginId: []
    }

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;
        this.setState({
            [form.name]: {
                ...this.state.melding,
                [input.name]: value
            }
        })
    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        this.setState({
            loginId: selectedOptionMulti
        })
    }

    render() {
        const {logins} = this.props
        return (
            <ContentWrapper>
                <Row>
                    <div className="col-md-12">
                        { /* START card */}
                        <Card className="card-default">
                            <CardBody>
                                <legend className="mb-4">Csv export</legend>
                                <fieldset>
                                    <div style={{ paddingTop: "10px" }} className="form-group row align-items-center">
                                        <label className="col-md-2 col-form-label">Login naam</label>
                                        <Col md={6}>
                                            <Select
                                                closeMenuOnSelect={false}
                                                name="loginId"
                                                isMulti
                                                value={this.state.loginId}
                                                onChange={(value) => this.handleChangeSelectMulti(value)}
                                                options={logins}
                                            />
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </div>
                                </fieldset>

                            </CardBody>
                            <CardFooter className="text-center">
                                <Row>
                                    <Col>
                                        <button className="btn btn-info" onClick={() => this.props.LoginToCsvOffices(this.state.loginId)}>Csv export bedrijven</button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-info" onClick={() => this.props.LoginToCsvUsers(this.state.loginId)}>Csv export gebruikers</button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </ContentWrapper>
        )
    }
}
const mapStateToProps = (state) => {
    var logins = state.firestore.ordered &&
        state.firestore.ordered.logins &&
        (state.firestore.ordered.logins.map(login => ({ value: login.id, label: login.loginName.valueStatus }))).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    var profile = state.firebase.profile
    return {
        logins,
        profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        LoginToCsvOffices: (loginId) => dispatch(LoginToCsvOffices(loginId)),
        LoginToCsvUsers: (loginId) => dispatch(LoginToCsvUsers(loginId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'logins'
    }])
)(NewLoginKlant)
