import firebase from '../../config/fbConfig';

let onSuccess = (message) => {
  return {
      type: 'SUCCESS',
      payload: message 
    }
  }
  
let resetState = () => {
  return {
    type: "RESET_STATUS"
  }
}

function onError(error) {
  return {
    type: 'ERROR',
    error 
  }
}

export const createLogin = (loginProp) => {
  return async (dispatch) => {
    const db = firebase.firestore();
    
    try {
      await db.collection('logins').add(loginProp)
      dispatch(onSuccess('Login succesvol aangepast'))
      return await setTimeout(() => { dispatch(resetState()) } , 3000)
    }
    catch (error) {
      dispatch(onError(error))
      return await setTimeout(() => { dispatch(resetState()) } , 3000)  
    } 
  }
}

export const updateLogin = (loginProp, loginId) => {
  return async (dispatch) => {
      const db = firebase.firestore();
      
      try {
        await db.collection('logins').doc(loginId).update(loginProp)

        dispatch(onSuccess('Login succesvol aangepast'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }        
  }
}

export const createLoginKlant = (loginProp, officeId) => {
  return async (dispatch, getState) => {
      const db = firebase.firestore();
      const email = getState().firebase.auth.email;
      const data = {
          ...loginProp,
          updateBy: email,
          lastUpdate: new Date(),
          officeId,
      }
      
      try {
        await db.collection(`loginsOffices`).add(data)

        dispatch(onSuccess('Login succesvol toegevoegd'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }
  }
}

export const updateLoginKlant = (loginProp, officeId, loginId) => {
  return async (dispatch, getState) => {
      const db = firebase.firestore();
      const email = getState().firebase.auth.email;
      const data = {
          ...loginProp.login,
          updateBy: email,
          lastUpdate: new Date()
      }
      
      try {
        await db.collection(`loginsOffices`).doc(loginId).update(data)

        dispatch(onSuccess('Login succesvol aangepast'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } ,3000)  
      }        
  }
}

export const getLoginInfo = (loginId) => {
  return async (dispatch) => {
      const db = firebase.firestore();

      try {
        await db.collection('logins').doc(loginId).get()

        dispatch(onSuccess('Logins succesvol opgehaald'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)  
      }
  }
}
export const deleteDoc = (collection, doc) => {
  return async (dispatch) => {
      const firestore = firebase.firestore();

      try {
        await firestore.collection(collection).doc(doc).delete()

        dispatch(onSuccess('succesvol verwijderd'))
        return await setTimeout(() => { dispatch(resetState()) } , 3000)
      }
      catch (error) {
        dispatch(onError(error))
        return await setTimeout(() => { dispatch(resetState()) } , 8000)  
      }
  }
}
