import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import {signOut} from '../../store/actions/authActions'

const Error500 = props => (
    <div className="abs-center wd-xl">
        <div className="text-center mb-4">
            <div className="mb-3">
                <i style={{fontSize: '3em'}} className="far fa-times-circle"></i>
            </div>
            <div className="text-lg mb-3">500</div>
            <p className="lead m-0">Dit is het MaXXus medewerkers portaal</p>
            <p>Klik op de link voor het klanten portaal</p>
        </div>
        <div className="text-center"><a href="https://portal.maxxus.nl"><button className="btn btn-info">Naar de klanten omgeving</button></a></div><br />
        <div className="text-center"><button className="btn btn-info" onClick={() => props.signOut()}>Log uit</button></div>
       
        <div className="p-3 text-center">
            <span className="mr-2">&copy;</span>
            <span>2019</span>
            <span className="mx-2">-</span>
            <span>Maxxus</span>

        </div>
    </div>
)

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

export default compose(
  connect(null, mapDispatchToProps)
)(Error500) 
