import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { getSubscriptionInfo, updateSubscription } from '../../../store/actions/SubscriptionActions';
import SubscriptionNew from './SubscriptionsNew';
import ContentWrapper from '../../Layout/ContentWrapper';
import firebase from '../../../config/fbConfig';
import { FormItem } from '../../DMComponents/FormComponents';

class SubscriptionsDetails extends Component {
    
    getInfo = (subscriptionId) => {
        const db = firebase.firestore();
        db.collection('subscriptions').doc(subscriptionId).get()
                .then(doc => {
                        var data = doc.data()
                        this.setState(data)
                }).catch((err) => {
                        console.log(err)
                });
    }

    validateOnChange = (event, exeption) => {
      const input = event.target;
      const value = input.type === 'checkbox' ? input.checked : input.type === 'float' ? parseFloat(input.value) : input.value;

      if (exeption && exeption === 'sellingPrice') {
        this.setState({
          sellingPrice: {
            ...this.state.sellingPrice,
            [input.name]: value,
          }
        })
      }
      else if (exeption && exeption === 'exact') {
        this.setState({
          exact: {
            ...this.state.exact,
            [input.name]: value,
          },
        })
      }
      else {
        this.setState({
          [input.name]: value,
        })
      } 
    }

    onSubmit = e => {
        this.props.updateSubscription(this.props.subscriptionId, this.state)
        e.preventDefault()

    }

    componentDidMount = () => {
        const subscriptionId = this.props.subscriptionId;
        this.getInfo(subscriptionId)
    }

    render() {
        const s = this.state;
        const { subscriptionId, statusSucces, statusError } = this.props

        if (subscriptionId === 'newsubscription') {
            return (
                <SubscriptionNew />
            )
        } 
        else if (subscriptionId !== 'newsubscription') {
            return (
                
                <ContentWrapper>
                    <div className="content-heading">
                            <div>
                                    <Link to={`/subscriptions`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                            </div>
                    </div>
                {s && 
                <Row>
                    <div className="col-md-12">
                        <form onSubmit={this.onSubmit} action="" name="gegevens">
                            <Card className="card-default">        
                                <CardBody>
                                    <legend className="mb-4">Artikel aanpassen</legend>
                                    <fieldset>

                                    <FormItem 
                                      name="subscriptionIdMaxxus"
                                      value={s.subscriptionIdMaxxus}
                                      type="text"
                                      onChange={this.validateOnChange}
                                      nameShow="Abonnement artikel ID Maxxus"
                                    />
                                    <div className="form-group row align-items-center">
                                    <label className="col-md-2 col-form-label">Abonnement artikel omschrijving</label>
                                      <Col md={ 6 }>
                                          <textarea
                                              name="description"
                                              rows="2"
                                              className="form-control note-editor note-editor"
                                              value={s.description}
                                              onChange={this.validateOnChange}
                                      /></Col>
                                      <Col md={ 2 }></Col>
                                    </div>

                                    <FormItem
                                        name="purchasePrice"
                                        value={s.purchasePrice}
                                        type="text"
                                        onChange={this.validateOnChange}
                                        nameShow="Abonnement artikel ID Maxxus"
                                    />
                                    <FormItem
                                        name="min"
                                        value={s.sellingPrice.min}
                                        placeholder="minimaal"
                                        type="text"
                                        onChange={event => this.validateOnChange(event, 'sellingPrice')}
                                        nameShow="Verkoopprijs"
                                    />
                                    <FormItem
                                        name="regular"
                                        value={s.sellingPrice.regular}
                                        placeholder="standaard"
                                        type="text"
                                        onChange={event => this.validateOnChange(event, 'sellingPrice')}
                                    />
                                    <FormItem
                                        name="onetimeCosts"
                                        value={s.onetimeCosts}
                                        type="text"
                                        onChange={this.validateOnChange}
                                        nameShow="Eenmalige kosten"
                                    />
                                    <FormItem
                                        name="period"
                                        value={s.period}
                                        type="text"
                                        onChange={this.validateOnChange}
                                        placeholder="periode in maanden"
                                        nameShow="Periode"
                                    />
                                    </fieldset>
                                    <legend className="mb-4">Velden Exact</legend>
                                    <fieldset>

                                    <FormItem
                                        name="buySection"
                                        value={s.exact.sellSection}
                                        type="text"
                                        onChange={event => this.validateOnChange(event, "exact")}
                                        nameShow="Rubriek verkopen"
                                    />
                                    <FormItem
                                        name="sellSection"
                                        value={s.exact.buySection}
                                        type="text"
                                        onChange={event => this.validateOnChange(event, "exact")}
                                        nameShow="Rubriek kostprijs"
                                    />
                                    </fieldset>
                                </CardBody>
                                {statusSucces ? <Alert color="success">{statusSucces}</Alert> : null}
                                {statusError ? <Alert color="danger">{statusError}</Alert> : null}
                                <CardFooter className="text-center">
                                            <button type="submit" className="btn btn-info">Abonnement aanpassen</button>
                                </CardFooter>
                            </Card>
                        </form>
                    </div>
                </Row>
                }
                </ContentWrapper>
                    
            )
        }

    }
}

const mapStateToProps = (state, ownProps) => {
    const subscriptionId = ownProps.match.params.subscriptionId;
    const statusError = state.logins.statusError;
    const statusSucces = state.logins.statusSucces;

    return {
        subscriptionId,
        statusError,
        statusSucces
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSubscriptionInfo: (subscriptionId) => getSubscriptionInfo(subscriptionId),
        updateSubscription: (loginProp, subscriptionId) => dispatch(updateSubscription(loginProp, subscriptionId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'subscriptions'
    }])
)(SubscriptionsDetails)