import React, { Component } from 'react'
import { Alert, Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import { newSubscription } from '../../../store/actions/SubscriptionActions';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link } from 'react-router-dom';
import { FormItem } from '../../DMComponents/FormComponents';


class SubscriptionsNew extends Component {
    
    state = {  
    }

    validateOnChange = (event, exeption) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.type === 'float' ? parseFloat(input.value) : input.value;

        if (exeption && exeption === 'sellingPrice') {
            this.setState({
                sellingPrice: {
                    ...this.state.sellingPrice,
                    [input.name]: value,
                }
            })
        }
        else if (exeption && exeption === 'exact') {
            this.setState({
                exact: {
                    ...this.state.exact,
                    [input.name]: value,
                },
            })
        }
        else {
            this.setState({
                [input.name]: value,
            })
        } 
    }

    onSubmit = e => {
        e.preventDefault()
        this.props.newSubscription(this.state)
    }
  
    render() {
        const { statusSucces, statusError } = this.props
        const s = this.state;
    return (
        <ContentWrapper>
        <div className="content-heading">
                <div>Nieuw Abonnement artikel
                        <small>Abonnement artikel toevoegen aan abonnement-artikellijst</small><br/>
                        <Link to={`/subscriptions`}><button className="btn btn-info"><i className="fas fa-arrow-left"></i>  Terug</button></Link>
                </div>
        </div>
        <Row>
            <div className="col-md-12">
                <form onSubmit={this.onSubmit} action="" name="gegevens">
                    <Card className="card-default">        
                      <CardBody>
                          <legend className="mb-4">Nieuw Abonnement artikel</legend>
                          <fieldset>
                            <FormItem
                                name="subscriptionIdMaxxus"
                                value={s.subscriptionIdMaxxus}
                                type="text"
                                onChange={this.validateOnChange}
                                nameShow="Abonnement artikel ID Maxxus"
                            />

                            <div className="form-group row align-items-center">
                                <label className="col-md-2 col-form-label">Abonnement artikel omschrijving</label>
                                <Col md={ 6 }>
                                      <textarea
                                          name="description"
                                          rows="2"
                                          className="form-control note-editor note-editor"
                                          value={s.description}
                                          onChange={this.validateOnChange}
                                      />
                                </Col>
                                <Col md={ 5 }>
                                </Col>
                            </div>

                            <FormItem
                                name="purchasePrice"
                                value={s.purchasePrice}
                                type="text"
                                onChange={this.validateOnChange}
                                nameShow="Inkoopprijs"
                            />
                            <FormItem
                                  name="min"
                                  value={s.minimal}
                                  placeholder="minimaal"
                                  type="text"
                                  onChange={event => this.validateOnChange(event, 'sellingPrice')}
                                  nameShow="Verkoopprijs"
                            />
                            <FormItem
                                  name="regular"
                                  value={s.regular}
                                  placeholder="standaard"
                                  type="text"
                                  onChange={event => this.validateOnChange(event, 'sellingPrice')}
                            />
                            <FormItem
                                name="onetimeCosts"
                                value={s.onetimeCosts}
                                type="text"
                                onChange={this.validateOnChange}
                                nameShow="Eenmalige kosten"
                            />
                            <FormItem
                                name="period"
                                value={s.period}
                                type="text"
                                onChange={this.validateOnChange}
                                placeholder="periode in maanden"
                                nameShow="Periode"
                            />

                          </fieldset>
                          <legend className="mb-4">Velden Exact</legend>
                          <fieldset>

                            <FormItem
                                name="buySection"
                                value={s.sellSection}
                                type="text"
                                onChange={event => this.validateOnChange(event, "exact")}
                                nameShow="Rubriek verkopen"
                            />
                            <FormItem
                                name="sellSection"
                                value={s.buySection}
                                type="text"
                                onChange={event => this.validateOnChange(event, "exact")}
                                nameShow="Rubriek kostprijs"
                            />

                          </fieldset>
                        </CardBody>
                        {statusSucces ? <Alert color="success">{statusSucces}</Alert> : null}
                        {statusError ? <Alert color="danger">{statusError}</Alert> : null}
                        <CardFooter className="text-center">
                            <button type="submit" className="btn btn-info">Abonnement Toevoegen</button>
                        </CardFooter>
                    </Card>
                </form>
            </div>
        </Row>
        </ContentWrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
    const statusError = state.subscriptions.statusError;
    const statusSucces = state.subscriptions.statusSucces;
    return {
        statusError,
        statusSucces
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newSubscription: (subscriptionProp) => dispatch(newSubscription(subscriptionProp)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([{
        collection: 'subscriptions'
    }])
)(SubscriptionsNew)
